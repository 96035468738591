import React from 'react';
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {event} from "services/analytics";
import history from 'store/history'
import * as Sentry from '@sentry/browser';

const mapStateToProps = state => {
  return {
    error: state.app.error,
  }
};

class ErrorBoundary extends React.Component {

  state = {
    error: null,
    message: null,
    hasError: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    const {error} = nextProps;

    if (error) {

      // backend вернул корректную ошибку
      if (error.response && error.response.data) {
        switch (error.response.data.status) {
          case 401:
            return history.push('/login');

          default:
        }
      }

      Sentry.withScope((scope) => {
        scope.setExtras(error.info);
        Sentry.captureException(error);
      });

      return {
        hasError: true,
        error: error,
        message: error.response && error.response.data ? error.response.data.message : error.message
      }
    }

    return {}
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });

    this.setState({
      hasError: true,
      message: error.message,
      error
    });
  }


  render() {
    if (this.state.hasError) {

      event(this.state.error.name + (this.state.error.code ? '_' + this.state.error.code : ''), this.state.message);

      return <article className="full-page error-page">
        <div className="center">
          <h1>Возникла ошибка!</h1>
          <div className="text-center">
            {this.state.hasError ? <p>{this.state.message}</p> : null}
            <a href="/"><Button color="primary">Перейти на главную</Button></a>
          </div>
        </div>
      </article>;
    }
    return this.props.children;
  }
}

export default connect(mapStateToProps)(ErrorBoundary);
