import './styles.css'

import React from 'react';
import {connect} from "react-redux";
import autoBind from "react-autobind";

import _ from "lodash";
import {numberFormat, queryStringParams} from "utils";
import {Link} from "react-router-dom";
import {Badge, Button} from "reactstrap";
import {Loader} from "web/components";
import InfiniteScroll from "react-infinite-scroller";
import * as objSelectors from "reducers/objReducer";
import * as geoSelectors from "reducers/geoReducer";
import * as objActions from "actions/obj";
import * as geoActions from "actions/geo";
import Geo from "./Geo";
import {ObjList} from "./components/ObjList"
import {FacetList} from "./components/FacetList";


function mapStateToProps(state) {

  const obj = objSelectors.getObjects(state);
  const geo = geoSelectors.getGeo(state);
  return {
    error: state.error,
    params: obj.params,
    hasMore: obj.objectsArray ? obj.hasMore : true,
    totalCount: obj.totalCount || 0,
    objectsArray: obj.objectsArray,
    facets: obj.facets,
    loading: obj.isLoading,
    selectedCity: geo.cityName,
    citiesArray: geoSelectors.getCities(state),
    geoId: geoSelectors.getSelectedCityId(state),
    areas: geoSelectors.getAreas(state),
    isCitySelected: geoSelectors.isCitySelected(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchObjects: () => dispatch(objActions.fetchObjects()),
  fetchCity: id => dispatch(geoActions.fetchCity(id)),
  setSearchParams: params => dispatch(objActions.setSearchParams(params)),
  fetchCities: () => dispatch(geoActions.fetchCities()),
  clearData: () => dispatch(objActions.clearData()),
  selectCity: geoId => dispatch(geoActions.selectCity(geoId)),
  addFavorite: item => dispatch(objActions.addFavorite(item)),
  delFavorite: item => dispatch(objActions.delFavorite(item)),
});

class ObjListContainer extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillMount() {
    let {params} = this.props.match;

    if (_.isEmpty(params)) return;

    if (!_.isEmpty(this.props.location.search)) {
      params = _.merge(queryStringParams(this.props.location.search), params);
    }

    params['uri'] = this.props.location.pathname;
    this.props.setSearchParams(params);
  }


  componentWillReceiveProps(nextProps, nextContext) {
    const {params} = nextProps.match;
    if (params === this.props.match.params || _.isEmpty(params)) return;
    params['uri'] = this.props.location.pathname;
    this.props.setSearchParams(params);
    window.scrollTo(0, 0)
  }

  onFavoriteClick(item) {
    if (item.favorite === true) {
      this.props.delFavorite(item)
    } else {
      this.props.addFavorite(item);
    }
  }

  renderActions() {

    if (!this.props.objectsArray) return null;

    const {facets, params} = this.props;
    const prms = _.compact(_.values(params)).length;


    return (
      <div>
        {facets && facets.length > 1 && !(this.props.params['property_id'] && this.props.params['type_id'])
          ? <FacetList items={this.props.facets}/>
          : <div className="obj-result">Найдено объектов: {numberFormat(this.props.totalCount)}</div>}
        <div className="bottom-fixed">
          <Link to="/search"><Button color="primary">Изменить параметры {prms > 0 ?
            <Badge color="danger">{prms}</Badge> : null}</Button>
          </Link>
        </div>
      </div>
    )
  }

  render() {

    if (!this.props.isCitySelected) {
      return <Geo {...this.props}/>;
    }

    return <InfiniteScroll
      hasMore={this.props.hasMore}
      loadMore={this.props.fetchObjects}
      loader={<Loader key={0} height={this.props.totalCount ? '2vh' : null}/>}
      threshold={1000}
    >
      {this.renderActions()}
      <ObjList items={this.props.objectsArray} onFavoriteClick={this.onFavoriteClick}/>
    </InfiniteScroll>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjListContainer);
