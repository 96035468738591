import React from "react";
import {Style} from "radium";
import {Link} from "react-router-dom";
import LazyLoad from "react-lazyload";
import convert from "htmr";

const SwipeList = ({items, onClick}) => {
  if (!items) return null;

  const frames = items.map((item) => {
    return (
      <li key={item.id}>
        <Link to={item.url} onClick={() => onClick(item)}>
          <LazyLoad height="30vh" offset={500}>
            <figure style={{backgroundImage: `url(${item.image ? item.image.replace(/480x360/, '160x120') : ''})`}}
                    alt=""/>
          </LazyLoad>
          <div
            className="price-reg">{item.type.id === 2 ? `${item.price_value}/${item.price.period.value}` : item.price_value}</div>
          <div className="title">{convert(item.title)}</div>
          <div>{item.geo.address}</div>
        </Link>
      </li>
    )
  });

  return (
    <div>
      <div className="widget-swipe">
        <Style
          scopeSelector=".widget-swipe"
          rules={{
            a: {
              color: '#444'
            },
            'a:visited': {
              color: '#551A8B'
            },
          }}/>
        <ul>{frames}</ul>
      </div>
    </div>);
}

export default SwipeList
