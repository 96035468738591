import './styles.css'

import _ from "lodash"
import React from 'react'
import {connect} from "react-redux"

import * as ownerActions from "actions/owner"
import * as ownerSelectors from "reducers/ownerReducer"
import {Loader} from "web/components"
import {Col, ListGroup, ListGroupItem, Row} from "reactstrap"
import {Link} from "react-router-dom"
import {numberFormat} from "utils"
import {event} from "services/analytics";


function mapStateToProps(state) {
  const owner = ownerSelectors.getOwner(state);
  return {
    loading: owner.isLoading,
    page: owner.page,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPage: id => dispatch(ownerActions.fetchOwnerPage(id)),
})

class OwnerViewPage extends React.Component {

  componentWillMount() {
    const {params} = this.props.match;
    if (!this.props.page || (this.props.page.id + '') !== params.id) {
      this.props.fetchPage(params.id);
    }
    event('owner_view', `${params.id}`);
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   const {params} = nextProps.match;
  //   if (!this.props.page || this.props.page.id !== params.id) {
  //     this.props.fetchPage(params.id);
  //   }
  //   event('owner_view', `${params.id}`);
  // }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  };

  _onClickPhone() {
    const {match} = this.props;
    event('owner_click_expand', `${match.params.id}`);
  }

  _renderContacts() {

    return (<div style={{marginTop: '2rem'}}>
        <div className="contacts-call">
          {/*<a href={`tel:${phone.replace(/[^+\d]/g, '')}`} onClick={this._onClickPhone.bind(this)}>{phone}</a>*/}
        </div>
      </div>
    );
  }

  _renderCounters() {

    const {page, location} = this.props;

    return (
      <ListGroup style={{margin: '2rem 0'}}>
        {_.map(page.counters, (item, k) => {
          return item.type ? (
            <ListGroupItem key={k}>
              <Link to={location.pathname.replace(/\/$/, '') + '/' + item.url}>
                <Row>
                  <Col xs="8">
                    {item.type}
                  </Col>
                  <Col xs="4">
                    <div className="counter">{numberFormat(item.count)}</div>
                  </Col>
                </Row>
              </Link>
            </ListGroupItem>
          ) : null
        })}
      </ListGroup>
    )
  }

  render() {

    if (!this.props.page || this.props.loading) {
      return <Loader/>;
    }

    const {page} = this.props;

    return <div className="owner">
      {page.image ? <figure style={{backgroundImage: `url(${page.image})`}}/> : null}
      <article>
        <div>{page.acc_type}</div>
        <h1>{page.name}</h1>
        <div>{page.city}</div>
        <div>На сайте {page.date}</div>
        {page.trust_date_experience ? <div>На рынке с {page.trust_date_experience} года</div> : null}
        {/*{page.phone ? this._renderContacts() : null}*/}

        {page.counters ? this._renderCounters() : null}

        <div>{page.description}</div>
      </article>

    </div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerViewPage)
