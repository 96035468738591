import "./styles.css";

import React from 'react';
import Carousel from 're-carousel'
import IndicatorCounter from "./indicator-counter";
import Buttons from './buttons'

const Gallery = ({images, onClick}) => {

  if (!images) {
    return null;
  }

  // одна фото - не листаем
  if (images.length === 1) {
    return (
      <div className="gallery">
        <figure style={{backgroundImage: `url(${images[0].src})`}} width="100%" alt=""/>
      </div>
    )
  }

  const frames = images.map((image, index) => {
    return <figure style={{backgroundImage: `url(${image.src})`}} width="100%" alt="" onClick={() => onClick({index})}/>
  });

  return (
    <div className="gallery">
      <Carousel frames={frames} widgets={[IndicatorCounter, Buttons]}/>
    </div>);
}

export default Gallery
