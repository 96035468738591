import bududomaService from 'services/bududoma';
import * as types from 'actions/types';
import * as actionTypes from "./types";

export const fetchEstatePage = (alias) => {
    return async (dispatch, getState) => {
            dispatch({type: types.ESTATE_PAGE_LOADING});

            const {geo} = getState();

            let params = {
                city_id: geo.geoId,
                alias: alias
            };

            const page = await bududomaService.getEstatePage(params).catch(error => {
              return dispatch({type: actionTypes.ERROR, error})
            });;

            if (page.error) {
                const error = page.error;
                dispatch({type: types.ERROR, error});
                return
            }

            dispatch({type: types.ESTATE_PAGE_FETCHED, page});
    };
};
