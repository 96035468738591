import * as objSelectors from 'reducers/objReducer';
import * as actionTypes from 'actions/types';
import bududomaService from 'services/bududoma';

export const setSearchParams = (params) => {
  return dispatch => {

    if (params['uri']) {
      const tr = {o: 'object_id', s: 'address', a: 'area'}
      params['uri'].split('/').forEach(i => {
        if (i[1] === '-') {
          let k = tr[i[0]]
          params[k] = i.substr(2);
        }
      })
    }

    if (params['property_id'] && params['property_id'] === 'newbuild') {
      params['property_id'] = 'flat';
      params['is_newbuilder'] = 1;
    }

    params = bududomaService.sanitizeParams(params);
    dispatch({type: actionTypes.OBJECTS_SEARCH_PARAMS, params});
  }
}

export const fetchObjects = () => {

  return async (dispatch, getState) => {
    try {

      const {geo, obj} = getState();

      if (obj.isLoading) {
        return
      }

      dispatch({type: actionTypes.OBJECTS_LOADING});

      let params = obj.params || {};
      params = params.merge({
        city_id: geo.geoId
      });

      const url = objSelectors.getNextHref(getState());
      const [objectsArray, totalCount, nextHref, facets] = await bududomaService.getObjects(url, params);

      dispatch({type: actionTypes.OBJECTS_FETCHED, totalCount, nextHref, objectsArray, facets});
    } catch (error) {
      dispatch({type: actionTypes.ERROR, error});
    }
  };
}

export const clearData = () => {
  return async (dispatch) => {
    dispatch({type: actionTypes.OBJECTS_CLEAR});
  }
}

export const fetchObject = (id) => {
  return async dispatch => {
    dispatch({type: actionTypes.OBJECT_LOADING});

    const object = await bududomaService.getObject(id)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    if (object.id) {
      dispatch(fetchSimilar(object.id))
    }

    if (object.userId) {
      dispatch(fetchUser(object.userId))
    }

    dispatch({type: actionTypes.OBJECT_FETCHED, object});
    dispatch({type: actionTypes.HISTORY_CLEAR});
  };
};

export const fetchUser = (userId) => {
  return async dispatch => {
    const user = await bududomaService.getUser(userId).catch(error => {
      return dispatch({type: actionTypes.ERROR, error})
    });

    dispatch({type: actionTypes.USER_FETCHED, user});
  };
};

export const fetchContacts = (userId, objId) => {
  return async dispatch => {
    const contacts = await bududomaService.getContacts(userId, objId)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    dispatch({type: actionTypes.CONTACTS_FETCHED, contacts});
  };
};

export const fetchSimilar = (id) => {
  return async (dispatch) => {
    dispatch({type: actionTypes.OBJECTS_SIMILAR_LOADING});
    const [items, totalCount, nextHref] = await bududomaService.getSimilar(id)
      .catch(error => {
        console.log(error);
      });

    dispatch({type: actionTypes.OBJECTS_SIMILAR_FETCHED, totalCount, nextHref, items});
  };
}

export const addFavorite = (prev) => {
  return async dispatch => {

    const item = {
      ...prev,
      favorite: true,
    };

    dispatch({type: actionTypes.FAVORITES_CHANGED, item});
    dispatch({type: actionTypes.OBJECTS_FAVORITE_CHANGED, item});
    const value = await bududomaService.addFavorite(item.id);
    if (item.favorite !== value) {
      dispatch({type: actionTypes.FAVORITES_CHANGED, prev});
      dispatch({type: actionTypes.OBJECTS_FAVORITE_CHANGED, prev});
    }
  };
};

export const delFavorite = (prev) => {
  return async dispatch => {

    const item = {
      ...prev,
      favorite: false,
    };

    dispatch({type: actionTypes.FAVORITES_CHANGED, item});
    dispatch({type: actionTypes.OBJECTS_FAVORITE_CHANGED, item});
    const value = await bududomaService.delFavorite(item.id);
    if (item.favorite !== value) {
      dispatch({type: actionTypes.FAVORITES_CHANGED, prev});
      dispatch({type: actionTypes.OBJECTS_FAVORITE_CHANGED, prev});
    }
  };
};
