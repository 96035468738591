import * as types from 'actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  list: null,
  page: null,
});

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {

    case types.HELP_LIST_LOADING:
        return state.merge({
            isLoading: true,
            tipList: null,
        });

    case types.HELP_LIST_FETCHED:

        return state.merge({
            totalCount: action.totalCount,
            nextHref: action.nextHref,
            hasMore: action.nextHref != null,
            isLoading: false,
            helpList: action.helpList,
        });

    case types.HELP_PAGE_LOADING:
        return state.merge({
            isLoading: true,
            page: null,
        });

    case types.HELP_PAGE_FETCHED:

        return state.merge({
        isLoading: false,
        page: action.page
      });

    default:
      return state;
  }

}

// selectors

export function getHelp(state) {
    return state.help;
}
