import * as selectors from 'reducers/historyReducer';
import * as actionTypes from 'actions/types';
import bududomaService from 'services/bududoma';
import * as types from "./types";

export const fetchHistory = () => {

  return async (dispatch, getState) => {
    if (selectors.isLoading(getState())) return;

    dispatch({type: actionTypes.HISTORY_LOADING});
    const url = selectors.getNextHref(getState());

    const [items, totalCount, nextHref] = await bududomaService.getHistory(url)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    dispatch({type: types.HISTORY_FETCHED, totalCount, nextHref, items});
  }
}
