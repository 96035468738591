import React from "react";
import ReactGA from "react-ga";
import ym, {YMInitializer} from "react-yandex-metrika";
import * as config from "constants/config";
import * as events from "constants/events";

const DEV = process.env.NODE_ENV === 'development';

ReactGA.initialize(config.GA_TRACKING_ID);
ReactGA.set({dimension1: 'webapp'});

/**
 * Просмотр страницы
 * @param location
 */
export const pageview = (location) => {

  if (DEV) return;

  ReactGA.pageview(location.pathname + location.search);
  ym('hit', location.pathname);

  const screen = window.screen;
  new Image().src = '//counter.yadro.ru/hit;' + config.LI_COUNTER_ID + '?r=' +
    ((typeof (screen) == 'undefined') ? '' : ';s' + screen.width +
      '*' + screen.height + '*' + (screen.colorDepth ? screen.colorDepth :
        screen.pixelDepth)) + ';u' + escape(document.URL) +
    ';h' + escape(document.title) + ';' + Math.random();
};

/**
 * Событие
 *
 * @param alias
 * @param label
 */
export const event = (alias, label) => {

  if (DEV) return;

  // отправка события в Google Analytics
  if (events.ga[alias]) {
    ReactGA.event({...events.ga[alias], label: label});
  }

  // отправка события в Яндекс Метрику
  if (events.ym[alias]) {
    ym('reachGoal',  events.ym[alias], {label: label});
  }
};

/**
 * Компонент для инициализации аналитики
 */
export default class Analytics extends React.Component {

  componentDidMount() {
    if (DEV) return;
    pageview(document.location)
  }

  render() {
    return <YMInitializer accounts={[48786110]} options={{webvisor: !DEV}} version="2"/>
  }
};
