import React from "react";
import {GeoObject, Map, YMaps, ZoomControl} from "react-yandex-maps";

const DynamicMap = ({location, height}) => {

  if (!location) return null;

  const mapState = {center: [location.lat, location.lon], zoom: 16, controls: []};

  return (
    <YMaps>
      <Map state={mapState} width='100%' height='100vh'>
        <ZoomControl
          options={{
            size: 'small',
            zoomDuration: 200,
            position: {right: '10px', bottom: '200px'}
          }}
        />
        <GeoObject
          geometry={{
            type: 'Point',
            coordinates: [location.lat, location.lon],
          }}
          options={{
            preset: 'islands#blueIcon',
            draggable: false,
          }}/>

      </Map>
    </YMaps>
  );
}

export default DynamicMap
