import './styles.css';

import React, {Component} from 'react';
import {connect} from "react-redux";
import convert from 'htmr';

import * as newsActions from "actions/news";
import * as newsSelectors from "reducers/newsReducer";
import {Gallery, Loader} from "web/components";
import {Link} from "react-router-dom";
import {event} from "services/analytics";


class NewsViewPage extends Component {

  componentWillMount() {
    const {match} = this.props;
    this.props.fetchPage(match.params.id);
    event('news_view', `${match.params.id}`);
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  };

  _onClickOther() {
    const {match} = this.props;
    event('news_click_other', `${match.params.id}`);
  }

  renderLinks() {
    return <div className="news-links">
      <hr/>
      <p>Вы всегда можете найти подходящие варианты:</p>
      <h3>Продажа</h3>
      <ul>
        <li><a href="/flat/sale/o-study/">Студия</a></li>
        <li><a href="/flat/sale/o-1-komnatnaja-kvartira/">1-комнатная квартира</a></li>
        <li><a href="/flat/sale/o-2-komnatnaja-kvartira/">2-комнатная квартира</a></li>
        <li><a href="/flat/sale/o-3-komnatnaja-kvartira/">3-комнатная квартира</a></li>
        <li><a href="/flat/sale/o-4-komnatnaja-kvartira/">4-комнатная квартира</a></li>
      </ul>
      <h3>Аренда</h3>
      <ul>
        <li><a href="/flat/rent/o-komnata-s-sosedjami/">Комната с соседом</a></li>
        <li><a href="/flat/rent/o-1-komnatnaja-kvartira/">1-комнатная квартира</a></li>
        <li><a href="/flat/rent/o-2-komnatnaja-kvartira/">2-комнатная квартира</a></li>
        <li><a href="/flat/rent/o-3-komnatnaja-kvartira/">3-комнатная квартира</a></li>
      </ul>
    </div>
  }

  render() {

    if (!this.props.page) {
      return <Loader/>;
    }

    const {page} = this.props;

    const transform = {
      div: (node, props, children) => {

        if (node.className === 'fotorama') {
          const items = node.children.map(i => {
            return {src: i.props.src};
          });
          return <Gallery images={items}/>;
        }

        // todo остальные типы вложений
        return <div {...props}>{node.children}</div>;
      },
    };

    return <div className="news">
      <figure style={{backgroundImage: `url(${page.image})`}} width="100%" alt=""/>
      <article>
        <h1>{page.header}</h1>
        <div className="news-info">{page.date} / {page.counter}</div>
        <div>{convert(page.body, {transform})}</div>
        <Link to="/news" onClick={this._onClickOther.bind(this)}>← другие новости</Link>
        {this.renderLinks()}
      </article>
    </div>;
  }
}

function mapStateToProps(state) {
  const news = newsSelectors.getNews(state);
  return {
    page: news.page,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPage: id => dispatch(newsActions.fetchNewsPage(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsViewPage);
