import _ from "lodash";
import * as types from 'actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  newsList: null,
  page: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.NEWS_PAGE_LOADING:
      return state.merge({
        isLoading: true,
        page: null,
      });
    case types.NEWS_PAGE_FETCHED:
      return state.merge({
        isLoading: false,
        page: action.page
      });
    case types.NEWS_LIST_LOADING:
      return state.merge({
        isLoading: true,
      });
    case types.NEWS_LIST_FETCHED:
      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        newsList: state.newsList ? _.concat(state.newsList, action.newsList) : action.newsList
      });
    default:
      return state;
  }
}

// selectors

export function getNews(state) {
  return state.news;
}

export function hasMore(state) {
  return state.news.hasMore;
}

export function getNextHref(state) {
  return state.news.nextHref;
}
