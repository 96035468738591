import {combineReducers} from "redux";

import app from './appReducer';
import geo from './geoReducer';
import obj from './objReducer';
import favorites from './favoritesReducer';
import history from './historyReducer';
import related from './relatedReducer';
import news from './newsReducer';
import tip from './tipReducer';
import help from './helpReducer';
import owner from './ownerReducer';
import ownerObj from './ownerObjReducer';
import estate from './estateReducer';

const index = combineReducers({
  app,
  geo,
  obj,
  favorites,
  history,
  related,
  news,
  tip,
  help,
  owner,
  ownerObj,
  estate,
});

export default index
