import './styles.css';

import React from 'react';
import autoBind from "react-autobind";
import {NavLink as RNavLink} from 'react-router-dom';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import logo from 'assets/images/bududoma-logo.svg';
import {
  IoLogoAndroid,
  IoLogoApple,
  IoMdBook,
  IoMdBriefcase,
  IoMdDesktop,
  IoMdHeart,
  IoMdPaper,
  IoMdPeople,
  IoMdPin,
  IoMdSearch,
  IoMdHome,
  IoMdHelpCircleOutline,
  IoMdArchive
} from "react-icons/io";
import {connect} from "react-redux";
import * as geoActions from "actions/geo";
import {isAndroid, isIOS} from 'react-device-detect';
import {APP_ANDROID_URL, APP_IOS_URL} from 'constants/config'


const mapStateToProps = state => {
  return {
    cityName: state.geo.cityName,
  }
};

const mapDispatchToProps = dispatch => ({
  selectCity: () => dispatch(geoActions.selectCity()),
});

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      collapsed: true
    };
  }

  componentDidMount() {
    window.prevScrollpos = window.pageYOffset;
    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  _handleScroll = () => {

    if (!this.state.collapsed) return;

    let prevScrollpos = window.prevScrollpos;

    const maxScroll = document.body.clientHeight - window.innerHeight;
    let currentScrollPos = window.pageYOffset;
    if (
      (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll)
      || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
      || (prevScrollpos <= 0 && currentScrollPos <= 0)
      || currentScrollPos < 200
    ) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-5.0rem"; // adjustable based your need
    }
    window.prevScrollpos = currentScrollPos;
  };

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeNavbar() {
    if (!this.state.collapsed) {
      return this.toggleNavbar();
    }
  }

  handleLogoClick() {
    window.scrollTo(0, 0);
    this.closeNavbar()
  }

  setCity() {
    this.toggleNavbar();
    this.props.selectCity();
  }

  render() {

    return (
      <Navbar id="navbar" color="faded" fixed="top" dark>
        <NavbarBrand tag={RNavLink} to="/" className="mr-auto" onClick={this.handleLogoClick}>
          <img className="logo" src={logo} width="150" alt="БудуДома.ру"/>
          <div className="city-name">{this.props.cityName || "Поиск недвижимости"}</div>
        </NavbarBrand>
        <NavLink exact tag={RNavLink} to="/favorite" onClick={() => this.closeNavbar()} className="fav-link"><IoMdHeart/></NavLink>
        <NavbarToggler onClick={this.toggleNavbar} className={!this.state.collapsed ? 'opened' : ''}/>
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav navbar>
            <NavItem><NavLink exact tag={RNavLink} to="/?setcity" onClick={this.setCity}><i><IoMdPin/></i>Выбор
              города</NavLink></NavItem>
            <NavItem><NavLink exact to="/" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdSearch/></i>Поиск</NavLink></NavItem>
            <NavItem>
              <NavLink to="/favorite" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdHeart/></i>Избранное</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/history" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdArchive/></i>История просмотров</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/developer" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdHome/></i>Застройщики</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/agency" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdBriefcase/></i>Агентства
                недвижимости</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/rieltor" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdPeople/></i>Риэлторы</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/news" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdPaper/></i>Новости</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about/tip" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdBook/></i>Советы</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/?nomobile" onClick={this.closeNavbar}><i><IoMdDesktop/></i>Полная версия
                сайта</NavLink>
            </NavItem>
            {isAndroid ? <NavItem><NavLink href={APP_ANDROID_URL}><i><IoLogoAndroid/></i>Установить приложение</NavLink></NavItem> : ''}
            {isIOS ?
              <NavItem><NavLink href={APP_IOS_URL}><i><IoLogoApple/></i>Установить приложение</NavLink></NavItem> : ''}
            <NavItem>
              <NavLink to="/support" tag={RNavLink} onClick={this.closeNavbar}><i><IoMdHelpCircleOutline/></i>Помощь и поддержка</NavLink>
            </NavItem>
            <NavItem className="system">
              {process.env.NODE_ENV === 'development' ? 'ENV_TYPE: ' + process.env.REACT_APP_ENV_TYPE : ''}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
