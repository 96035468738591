import React from "react";

class ChatraPage extends React.Component {

  componentDidMount() {
    // if (process.env.NODE_ENV !== "production") return null;

    // todo добавить данные пользователя после авторизации
    let {user} = this.props;

    window.ChatraSetup = {
      disabledOnMobile: true,
      mode: 'frame',
      injectTo: 'chatra-wrapper',
    };

    return (
      <script>
        {(function (d, w, c, u) {
          w.ChatraID = "fAsvxyutKrG6FAXCe";

          if (u && u.email && u.name) {
            w.ChatraIntegration = {name: u.name, email: u.email};
          } else {
            w.ChatraIntegration = {};
          }

          var s = d.createElement("script");
          w[c] =
            w[c] ||
            function () {
              (w[c].q = w[c].q || []).push(arguments);
            };
          s.async = true;
          s.src = "https://call.chatra.io/chatra.js";
          if (d.head) d.head.appendChild(s);
        })(document, window, "Support", user)}
        ;
      </script>
    );
  }

  render() {
    return <div id="chatra-wrapper" style={{height: '100%'}}></div>;
  }

}

export default ChatraPage
