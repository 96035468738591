// список событий

// мапинг метрик в Google Analytics
export const ga = {
  'adv_show': {category: 'Реклама приложения', action: 'Показ окна'},
  'adv_click_close': {category: 'Реклама приложения', action: 'Нажатие на закрыть'},
  'adv_click_skip': {category: 'Реклама приложения', action: 'Нажатие на пропустить'},
  'adv_click_install': {category: 'Реклама приложения', action: 'Нажатие на установить'},

  'err_400': {category: 'Ошибка', action: '400'},
  'err_500': {category: 'Ошибка', action: '500'},

  'news_view': {category: 'Новости', action: 'Просмотр новости'},
  'news_click_other': {category: 'Новости', action: 'Нажатие на другие новости'},

  'obj_view': {category: 'Объявление', action: 'Просмотр объявления'},
  'obj_click_similar': {category: 'Объявление', action: 'Нажатие на похожие'},
  'obj_click_expand': {category: 'Объявление', action: 'Нажатие на раскрыть контакты'},
  'obj_make_call': {category: 'Объявление', action: 'Нажатие на телефон'},

  'owner_view': {category: 'Продавец', action: 'Просмотр карточки'},
  'owner_click_expand': {category: 'Продавец', action: 'Нажатие на раскрыть контакты'},
};

// мапинг метрик в Яндекс Метрику
export const ym = {
  'obj_view': 'obj_view',
  'obj_make_call': 'phone_call',
};
