import _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from 'actions/types';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  objectsArray: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FAVORITES_LOADING:
      return state.merge({
        isLoading: true,
      });
    case types.FAVORITES_FETCHED:
      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        objectsArray: state.objectsArray == null ? action.items : _.concat(state.objectsArray, action.items)
      });
    case types.FAVORITES_CLEAR:
      return state.merge({
        totalCount: null,
        nextHref: null,
        hasMore: true,
        objectsArray: null,
        isLoading: false,
      });
    case types.FAVORITES_GC:
      return state.merge({
        objectsArray: _.filter(state.objectsArray, {favorite: true}),
      });
    case types.FAVORITES_CHANGED:
      const object = action.item;
      const i = _.findIndex(state.objectsArray, {id: object.id});

      const items = state.objectsArray ? state.objectsArray : [];

      if (i < 0)
        return state.merge({
          object: object,
          objectsArray: [object, ...items]
        });

      const result = [
        ..._.slice(items, 0, i),
        {
          ...items[i],
          favorite: object.favorite
        },
        ..._.slice(items, i + 1)
      ];

      return state.merge({
        object: object,
        objectsArray: result,
      });
    default:
      return state;
  }
}

// selectors

export function getFavorites(state) {
  return state.favorites;
}

export function hasMore(state) {
  return state.favorites.hasMore;
}

export function getNextHref(state) {
  return state.favorites.nextHref;
}

export function isLoading(state) {
  return state.favorites.isLoading;
}
