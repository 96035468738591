import bududomaService from 'services/bududoma';
import * as actionTypes from "./types";

export const fetchOwnerPage = (id) => {
  return async (dispatch, getState) => {
    dispatch({type: actionTypes.OWNER_PAGE_LOADING});

    const {geo} = getState();
    const params = {
      city_id: geo.geoId || 0,
    };
    const page = await bududomaService.getOwnerPage(id, params)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    dispatch({type: actionTypes.OWNER_PAGE_FETCHED, page});
  };
}

export const setParams = (params) => {
  return (dispatch, getState) => {
    const {geo} = getState();
    params['city_id'] = geo.geoId || 0;
    dispatch({type: actionTypes.OWNER_SEARCH_PARAMS, params});
  }
}

export const fetchOwnerList = () => {
  return async (dispatch, getState) => {

    const {owner} = getState();

    if (owner.isLoading) return;

    dispatch({type: actionTypes.OWNER_LIST_LOADING});

    const params = owner.params || {};
    const url = owner.nextHref;

    const [items, totalCount, nextHref] = await bududomaService.getOwnerList(url, params)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    dispatch({type: actionTypes.OWNER_LIST_FETCHED, totalCount, nextHref, items});
  };
};

export const setSearchParams = (params) => {
  return (dispatch, getState) => {
    const {geo} = getState();
    params = bududomaService.sanitizeParams(params);
    params['city_id'] = geo.geoId || 0;
    dispatch({type: actionTypes.OWNER_OBJECTS_SEARCH_PARAMS, params});
  }
}

export const fetchObjects = () => {

  return async (dispatch, getState) => {
    try {

      const {ownerObj} = getState();

      if (ownerObj.isLoading) {
        return
      }

      dispatch({type: actionTypes.OWNER_OBJECTS_LOADING});

      const params = ownerObj.params;
      const url = ownerObj.nextHref;
      const [items, totalCount, nextHref, facets] = await bududomaService.getObjects(url, params)
        .catch(error => {
          return dispatch({type: actionTypes.ERROR, error})
        });

      dispatch({type: actionTypes.OWNER_OBJECTS_FETCHED, totalCount, nextHref, items, facets});
    } catch (error) {
      dispatch({type: actionTypes.ERROR, error});
    }
  };
}
