import bududomaService from 'services/bududoma';
import * as types from './types';

export const fetchHelp = (id) => {
  return async (dispatch) => {
    try {
      dispatch({type: types.HELP_PAGE_LOADING});
      const page = await bududomaService.getHelpPage(id);
      dispatch({type: types.HELP_PAGE_FETCHED, page});
    } catch (error) {
      console.error(error);
    }
  };
}

export const  fetchHelpList = () => {
    return async (dispatch) => {
        try {
            dispatch({type: types.HELP_LIST_LOADING});
            const [totalCount, nextHref, helpList] = await bududomaService.getHelpList();
            dispatch({type: types.HELP_LIST_FETCHED, totalCount, nextHref, helpList});
        } catch (error) {
            console.error(error);
        }
    };
};
