import 'bootstrap/dist/css/bootstrap.min.css';
import 'web/styles.css';

import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'

import store from 'store/configure'
import history from 'store/history'
import Analytics, {pageview} from "services/analytics"
import ErrorBoundary from "web/screens/Error/ErrorBoundary";
import Routes from 'web/navigation/routes';

// pageview on change path
history.listen(location => {
  pageview(location);
});

ReactDOM.render(
  <Provider store={store}>
    <Analytics/>
    <ErrorBoundary>
      <Router history={history}>
        <Routes/>
      </Router>
    </ErrorBoundary>

  </Provider>,
  document.getElementById('root')
);

