import * as selectors from 'reducers/favoritesReducer';
import * as types from 'actions/types';
import bududomaService from 'services/bududoma';
import * as actionTypes from "./types";

export const fetchFavorites = () => {

  return async (dispatch, getState) => {
      if (selectors.isLoading(getState())) return;

      dispatch({type: types.FAVORITES_LOADING});

      const url = selectors.getNextHref(getState());
      const [items, totalCount, nextHref] = await bududomaService.getFavorites(url)
        .catch(error => {
          return dispatch({type: actionTypes.ERROR, error})
        });

      dispatch({type: types.FAVORITES_FETCHED, totalCount, nextHref, items});
  };
}

export const clearFavorites = () => {
  return async (dispatch) => {
    dispatch({type: types.FAVORITES_CLEAR});
  }
}

export const removeDeleted = () => {
  return async (dispatch) => {
    dispatch({type: types.FAVORITES_GC});
  }
}
