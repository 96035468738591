// словарь основных значений (аналог PropertyStatic)

export const type = {
  1: {alias: 'sale', name: 'Продажа'},
  2: {alias: 'rent', name: 'Аренда'}
};

export const floor = {
  1: {name: 'Только первые'},
  2: {name: 'Кроме первых'},
  3: {name: 'Кроме первых и последних'},
  4: {name: 'Только последние'}
};

export const property = {
  1: {alias: 'flat', name: 'Квартиры'},
  // 2: {alias: 'newbuild', name: 'Новостройки'},
  3: {alias: 'commercial', name: 'Коммерческая'},
  4: {alias: 'cottage', name: 'Загородная'},
  5: {alias: 'ground', name: 'Земля'},
  6: {alias: 'garage', name: 'Гаражи'},
};

export const object = {
  'flat': {
    1: {name: '1-комнатная квартира'},
    2: {name: '2-комнатная квартира'},
    3: {name: '3-комнатная квартира'},
    4: {name: '4-комнатная квартира'},
    5: {name: '5-комнатная квартира'},
    9: {name: 'Комната с соседом'},
    15: {name: 'Студия'},
  },
  'newbuild': {
    1: {name: '1-комнатная квартира'},
    2: {name: '2-комнатная квартира'},
    3: {name: '3-комнатная квартира'},
    4: {name: '4-комнатная квартира'},
    5: {name: '5-комнатная квартира'},
    9: {name: 'Комната с соседом'},
    15: {name: 'Студия'},
  },
  'commercial': {
    1: {name: 'Готовый бизнес'},
    2: {name: 'Офисное помещение'},
    3: {name: 'Торговые площади'},
    4: {name: 'Универсальное помещение'},
    5: {name: 'Производственное помещение'},
  },
  'cottage': {
    1: {name: 'Дом'},
    2: {name: 'Часть дома'},
    3: {name: 'Коттедж'},
    4: {name: 'Часть коттеджа'},
    5: {name: 'Дачи'},
  },
  'ground': {
    1: {name: 'Землеотвод'},
    2: {name: 'Садовый участок'},
    3: {name: 'Коммерческая земля'},
    5: {name: 'Земельный участок'},
  },
  'garage': {
    1: {name: 'Железный гараж'},
    2: {name: 'Капитальный гараж'},
    3: {name: 'Парковочное место'},
  }

};

export const acctype = {
    1: {alias: 'agency', name: 'Агентства недвижимости'},
    2: {alias: 'developer', name: 'Застройщики'},
    3: {alias: 'rieltor', name: 'Риэлторы'},
};
