import React from 'react';
import {connect} from "react-redux";
import autoBind from "react-autobind";
import * as objActions from "actions/obj";
import * as favActions from "actions/favorites";
import {Loader} from "web/components";
import InfiniteScroll from "react-infinite-scroller";
import * as favSelectors from "reducers/favoritesReducer";
import {ObjList} from "./components/ObjList";

class ObjFavorites extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.removeDeleted();
  }

  onFavoriteClick(item) {
    if (item.favorite === true) {
      this.props.delFavorite(item)
    } else {
      this.props.addFavorite(item);
    }
  }

  render() {
    return <InfiniteScroll
      hasMore={this.props.hasMore}
      loadMore={this.props.fetchFavorites}
      loader={<Loader key={0} height={this.props.totalCount ? '2vh' : null}/>}
    >
      <h1 className="text-left">Избранное</h1>
      <ObjList items={this.props.objectsArray} onFavoriteClick={this.onFavoriteClick}/>
      {this.props.totalCount === undefined && this.props.objectsArray.length === 0 ?
        <div className="content"><p>Список пуст</p></div> : ''}
    </InfiniteScroll>
  }
}

function mapStateToProps(state) {
  const fav = favSelectors.getFavorites(state);
  return {
    hasMore: fav.hasMore,
    totalCount: fav.totalCount,
    objectsArray: fav.objectsArray,
    loading: fav.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchFavorites: () => dispatch(favActions.fetchFavorites()),
  clearFavorites: () => dispatch(favActions.clearFavorites()),
  removeDeleted: () => dispatch(favActions.removeDeleted()),
  addFavorite: item => dispatch(objActions.addFavorite(item)),
  delFavorite: item => dispatch(objActions.delFavorite(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjFavorites);
