import './styles.css';

import React from 'react';

const Loader = ({height}) =>
  <div className={height ? 'loader-inline' : 'loader-fullscreen'} style={{height: height}}>
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </div>;

export default Loader
