import _ from 'lodash';
import React from "react";
import {Style} from "radium";
import {Link} from "react-router-dom";
import LazyLoad from "react-lazyload";
import FavIcon from "./FavIcon";

const ObjListItem = ({item, onFavoriteClick}) => {

  return (
    <li className={item.active ? "obj-item" : "obj-item-notactive"} key={item.id} data-id={item.id}
        data-services={item.active && item.services ? item.services : null}>
      <Link to={item.active ? item.url : '#'}>
        <div className="obj-photo">
          <LazyLoad height="20vh" offset={500}>
            {item.image ? <figure style={{backgroundImage: `url(${item.image})`}}/> : ''}
          </LazyLoad>
        </div>
      </Link>
      {onFavoriteClick ? <FavIcon active={item.favorite} onClick={() => onFavoriteClick(item)}/> : null}
      <Link to={item.active ? item.url : '#'}>
        <div className="obj-description">
          <span className="price-reg">{item.price_value ? `${item.price_value}` : 'Договорная'}</span>
          {item.price_etc ? <span className="price-sq">{item.price_etc}</span> : null}
          <div className="title">{item.title_full}</div>
          {item.newflat ? <div className="newflat">Новостройка</div> : null}
          <div className="address">{item.geo.city}, {item.geo.address}</div>
          {item.geo.metro ? <div className="metro">{_.map(item.geo.metro, i => {
            return <span className="metro-icon" key={i}>{i}</span>
          }).splice(0, 3)}</div> : null}
        </div>
      </Link>
      {item.active && item.call ? <div className="notice">{item.call}</div> : null}
      {!item.active ? <div className="notice">Объявление снято с публикации</div> : null}
    </li>
  )
}

export const ObjList = ({items, onFavoriteClick}) => {
  if (!items) return null;

  return (<ul className="obj-list">
    <Style
      scopeSelector=".obj-list"
      rules={{
        a: {
          color: '#444'
        },
        'a:visited *': {
          color: '#551A8B'
        },
      }}/>
    {items.map((item, index) => <ObjListItem key={index} item={item} onFavoriteClick={onFavoriteClick}/>)}
  </ul>)
};



