import React, {Component} from 'react'
import {connect} from "react-redux";
import * as relatedActions from "actions/related";
import {Loader} from "web/components";
import InfiniteScroll from "react-infinite-scroller";
import * as relatedSelectors from "reducers/relatedReducer";
import {ObjList} from "./components/ObjList";
import autoBind from "react-autobind";

class ObjRelated extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  _fetchRelated() {
    const id = this.props.match.params.id;
    return this.props.fetchRelated(id);
  }

  render() {

    return <InfiniteScroll
      hasMore={this.props.hasMore}
      loadMore={this._fetchRelated}
      loader={<Loader key={0} height={this.props.totalCount ? '2vh' : null}/>}
    >
      <h1 className="text-left">Похожие варианты</h1>
      <ObjList items={this.props.items}/>
      {this.props.totalCount === undefined && this.props.items.length === 0 ?
        <div className="content"><p>Список пуст</p></div> : ''}
    </InfiniteScroll>
  }
}

function mapStateToProps(state) {
  const related = relatedSelectors.getRelated(state);
  return {
    hasMore: related.items ? related.hasMore : true,
    totalCount: related.totalCount,
    items: related.items,
    isLoading: related.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchRelated: (id) => dispatch(relatedActions.fetchRelated(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjRelated);
