import * as selectors from 'reducers/relatedReducer';
import * as actionTypes from 'actions/types';
import bududomaService from 'services/bududoma';
import * as types from "./types";

export const fetchRelated = (id) => {

  return async (dispatch, getState) => {

    const related = selectors.getRelated(getState())

    if (related.isLoading) return;

    dispatch({type: actionTypes.RELATED_LOADING});
    const url = related.nextHref;

    const [items, totalCount, nextHref] = await bududomaService.getRelated(id, url)
      .catch(error => {
        return dispatch({type: actionTypes.ERROR, error})
      });

    dispatch({type: types.RELATED_FETCHED, totalCount, nextHref, items});
  }
}
