import './styles.css'

import _ from "lodash"
import React from 'react'
import {connect} from "react-redux"

import * as estateActions from "actions/estate"
import * as estateSelectors from "reducers/estateReducer"
import {Gallery, Loader, DynamicMap} from "web/components"
import {Col, ListGroup, ListGroupItem, Row} from "reactstrap"
import {Link} from "react-router-dom"
import {numberFormat} from "utils"
import {event} from "services/analytics";

class EstateViewPage extends React.Component {

  componentWillMount() {
    const {match} = this.props;
    this.props.fetchPage(match.params.alias);
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  };

  _onClickPhone() {
    const {match} = this.props;
    event('owner_click_expand', `${match.params.id}`);
  }

  _renderContacts() {

    const {phone} = this.props.page;

    return (<div style={{marginTop: '2rem'}}>
        <div className="contacts-call">
          <a href={`tel:${phone.replace(/[^+\d]/g, '')}`} onClick={this._onClickPhone.bind(this)}>{phone}</a>
        </div>
      </div>
    );
  }

  _renderCounters() {

    const {page, location} = this.props;

    return (
      <ListGroup>
        {_.map(page.counters, (item, k) => {
          return (
            <ListGroupItem key={k}>
              <Link to={location.pathname + '/' + item.url}>
                <Row>
                  <Col xs="8">
                    {item.type}
                  </Col>
                  <Col xs="4">
                    <div className="counter">{numberFormat(item.count)}</div>
                  </Col>
                </Row>
              </Link>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    )
  }

  render() {

    if (!this.props.page) {
      return <Loader/>;
    }

    const {page} = this.props;

    return <div className="owner">
      <div className="obj-photo">
        {page.images ? <Gallery images={page.images}/> : ''}
      </div>
      <article>
        <h1>{page.name}</h1>
        <div>{page.address}</div>

      </article>

      <DynamicMap location={page.location} />

      {page.counters ? this._renderCounters() : null}
      <article>
        <div>{page.description}</div>

      </article>

    </div>;
  }
}

function mapStateToProps(state) {
  const estate = estateSelectors.getEstate(state);
  return {
    page: estate.page,
    error: state.error,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPage: alias => dispatch(estateActions.fetchEstatePage(alias)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EstateViewPage)
