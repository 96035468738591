import React, {Component} from 'react';
import {connect} from "react-redux";
import convert from 'htmr';

import * as helpActions from "actions/help";
import * as helpSelectors from "reducers/helpReducer";
import {Loader, Gallery} from "../../components";
import {Link} from "react-router-dom"

class HelpViewPage extends Component {

  componentWillMount() {
    const {match} = this.props;
    if (typeof match.params.page === "undefined") match.params.id = 16;
    if (match.params.page === "contacts") match.params.id = 13;
    if (match.params.page === "rules") match.params.id = 14;
    if (match.params.page === "adver") match.params.id = 15;
    this.props.fetchPage(match.params.id);
  }

  render() {

    if (!this.props.page) {
      return <Loader/>;
    }

    const {page} = this.props;

    const transform = {
      div: (node, props, children) => {

        if (node.className === 'fotorama') {

          const items = node.children.map( i => {
            return {src: i.props.src};
          });

          return <Gallery items={items}/>;
        }
        return <div {...props}>{children}</div>;
      },
    };

    return <article className="tip">
      <h1>{page.header}</h1>
        <div>{convert(page.body, {transform})}</div>

        <Link to="/about/help">← еще почитать</Link>

    </article>;
  }
}

function mapStateToProps(state) {
  const help = helpSelectors.getHelp(state);

  return {
    page: help.page,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPage: id => dispatch(helpActions.fetchHelp(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpViewPage);
