import _ from 'lodash';
import axios from "axios";
import moment from "moment";
import {numberFormat, plural, relative, urlParams} from "utils"
import * as dic from "constants/dictionary"
import {ENV_TYPE} from "constants/config"

const BUDUDOMA_API = 'https://api.bududoma.ru';
const BUDUDOMA_WEB = 'https://{domain}.bududoma.ru';
const BUDUDOMA_IMG = 'https://cdn.bududoma.ru';
const IMG_SIZE = '480x360';
const LIMIT = 10;

export const BUDUDOMA_SEARCH_FIELDS = [
  'city_id', 'area_id', 'address', 'type_id', 'object_id', 'user_id', 'property_id', 'price_from',
  'price_to', 'is_foto', 'is_self_seller', 'is_newbuilder', 'acc_type', 'limit', 'floor_id', 'area_from', 'area_to',
  // 'area'
];

const HttpClient = axios.create({
  baseURL: BUDUDOMA_API + ENV_TYPE,
  crossDomain: true,
  withCredentials: true,
  credentials: 'include',
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

HttpClient.interceptors.response.use(undefined,
  error => {
    return Promise.reject(error);
  });

/**
 * Сервис для работы с api.bududoma.ru
 */
class BududomaService {

  // app platform
  platform = null;

  // user token
  token = null;

  // device id
  uuid = null;

  getConfig() {

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'X-App-Version': this.platform,
      }
    };

    if (this.token) config['headers']['Authorization'] = 'Token ' + this.token;
    if (this.uuid) config['headers']['X-Device-Id'] = this.uuid;

    return config;
  }

  sanitizeParams(params) {
    return _.pickBy(params, function (v, k) {
      return _.includes(BUDUDOMA_SEARCH_FIELDS, k) && v != null && v !== 0 && v !== "" && v !== false && v !== "0";
    });
  }

  async getCities(loc) {
    const url = `/v1/geo` + (loc != null ? `?loc=${loc}` : '');

    return await HttpClient.get(url)
      .then(res => {
        if (!res.data.items) {
          return null;
        }

        return _.keyBy(res.data.items.map(item => {
          return {
            geoId: item.id,
            title: item.name,
            domain: _.get(item, 'domain'),
            url: _.replace(BUDUDOMA_WEB, "{domain}", _.get(item, 'domain')),
            cnt: _.get(item, 'cnt', 0),
            region: _.get(item, 'region_name'),
            general: _.get(item, 'general', false),
          }
        }), 'geoId')
      });
  }

  async getCity(id) {
    return await HttpClient.get(`/v1/geo/${id}`)
      .then(res => {
        const item = res.data;

        if (!item) {
          return null;
        }

        return {
          geoId: item.id,
          title: item.name,
          domain: _.get(item, 'domain'),
          url: _.replace(BUDUDOMA_WEB, "{domain}", _.get(item, 'domain')),
          cnt: _.get(item, 'cnt', 0),
          region: _.get(item, 'region_name'),
          general: _.get(item, 'general', false),
          areas: item.areas,
        }
      });
  }

  _decorateObjectOld = (item) => {

    if (item === null || item.geo === null) return null;

    const price = item.price.value ? numberFormat(item.price.value) : null;
    const type = dic.type[item.type_id].alias;
    const property = dic.property[item.property_id].alias;
    const currency = item.price && item.price.currency ? item.price.currency : 'руб';
    const cost_unit = item.price && item.price.cost_unit ? ', ' + numberFormat(item.price.cost_unit) + ' '
      + item.price.unit.replace(/<sup>2<\/sup>/, '²') : '';
    const period = item.price && item.price.period ? ', ' + item.price.period.value : '';

    return {
      id: item.id,
      url: `/${property}/${type}/${item.id}`,
      price_value: price + ' ' + currency,
      price_etc: dic.type[item.type_id].name + (item.type_id === 1 ? cost_unit : period),
      price_unit: item.price.unit ? item.price.unit : '',
      price_currency: item.price.currency ? item.price.currency : 'руб',
      image: item.image ? item.image.url.replace(/(\d+)x(\d+)/, IMG_SIZE) : `${BUDUDOMA_IMG}/images/no_photo.png`,
      hasphoto: !!item.images,
      // images: item.images && item.images.length ? _.map(item.images, image => {
      //   if (typeof image.url === "undefined") return {src: `${BUDUDOMA_IMG}/images/no_photo.png`};
      //   return {src: image.url.replace(/(\d+)x(\d+)/, IMG_SIZE)};
      // }) : [{src: `${BUDUDOMA_IMG}/images/no_photo.png`}],
      title: item.title,
      title_full: item.title + (item.body.area ? `, ${item.body.area} м²` : '') + (item.body.floor && item.body.floors_total ? `, ${item.body.floor}/${item.body.floors_total} эт` : ''),
      title_short: item.title + '<br/>' + (item.body.area ? `${item.body.area} м²` : '') + (item.body.floor && item.body.floors_total ? `, ${item.body.floor}/${item.body.floors_total} эт` : ''),
      address: item.geo && item.geo.street ? (item.geo.street.name + (item.geo.house ? `, ${item.geo.house}` : '')) : '',
      geo: {
        address: item.geo && item.geo.street ? (item.geo.street.name + (item.geo.house ? `, ${item.geo.house}` : '')) : '',
        city: item.geo.city.name,
      },
      area: item.geo ? item.geo.city.name + (item.geo.area ? `, ${item.geo.area.name}` : '') : '',
      metro: item.geo.metro ? item.geo.metro : null,
      newflat: item.body.new_flat_id === 1,
      type: item.type,
      description: item.body.description,
      services: item.services,
      favorite: item.favorite && item.favorite === true,
      active: item.f_status === 0,
      call: item.call,
      user: item.user,
    }
  };

  _decorateObject = (item) => {
    if (item === null || item.geo === null) return null;

    item['url'] = relative(item.url);
    item['active'] = item.f_status === 0;
    item['price_value'] = item.price.value ? numberFormat(item.price.value) + ' ' + item.price.currency : item.price;
    item['price_etc'] = item.type.id === 2 ? item.type.name + (item.price.period.value ? ', ' + item.price.period.value : '') : (item.price.cost_unit ? numberFormat(item.price.cost_unit) + ' ' + item.price.unit : null);
    item['image'] = item.image ? item.image.replace(/(\d+)x(\d+)/, IMG_SIZE) : `${BUDUDOMA_IMG}/images/no_photo.png`;

    return item;
  }

  async getObjects(url, params) {

    const args = urlParams(this.sanitizeParams(params));
    const fetchUrl = url ? url : `/v1/obj?${args}&limit=${LIMIT}&scope=list`;
    return await this.getDecoratedObjects(fetchUrl);
  }

  async getSimilar(id) {
    const fetchUrl = `/v1/obj/${id}/similar?distance=1km&limit=${LIMIT}&scope=list`;
    return await this.getDecoratedObjects(fetchUrl);
  }

  async getRelated(id, url) {
    const fetchUrl = url ? url : `/v1/obj/${id}/similar?distance=30km&limit=${LIMIT}&scope=list`;
    return await this.getDecoratedObjects(fetchUrl);
  }

  async getFavorites(url) {
    const fetchUrl = url ? url : `/v1/favorite?limit=${LIMIT}&scope=list`;
    return await this.getDecoratedObjects(fetchUrl);
  }

  async getHistory(url) {
    const fetchUrl = url ? url : `/v1/history?limit=${LIMIT}&scope=list`;
    return await this.getDecoratedObjects(fetchUrl);
  }

  getDecoratedObjects(fetchUrl) {
    return HttpClient.get(fetchUrl, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }
        return [
          _.map(res.data.items, item => {
            return this._decorateObject(item)
          }),
          _.get(res.data, '_meta.totalCount'),
          _.get(res.data, '_links.next.href'),
        ];
      });
  }

  async getObject(id) {

    const fetchUrl = `/v1/obj/${id}`;

    return await HttpClient.get(fetchUrl, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;
        const price = item.price.value ? numberFormat(item.price.value) : null;
        const type = dic.type[item.type_id].alias;
        const property = dic.property[item.property_id].alias;
        const currency = item.price && item.price.currency ? item.price.currency : 'руб';
        const cost_unit = item.price && item.price.cost_unit ? ', ' + numberFormat(item.price.cost_unit) + ' '
          + item.price.unit.replace(/<sup>2<\/sup>/, '²') : '';
        const period = item.price && item.price.period ? ', ' + item.price.period.value : '';

        return {
          id: item.id,
          url: `/${property}/${type}/${item.id}`,
          original_id: item.original_id ? item.original_id : item.id,
          dt_update: moment(item.datetime_update).format("DD.MM.YYYY"),
          price: price + ' ' + currency,
          price_sq: dic.type[item.type_id].name + (item.type_id === 1 ? cost_unit : period),
          image: item.image ? item.image.url.replace(/(\d+)x(\d+)/, IMG_SIZE) : `${BUDUDOMA_IMG}/images/no_photo.png`,
          images: item.images && item.images.length ? _.map(item.images, image => {
            if (typeof image.url === "undefined") return {src: `${BUDUDOMA_IMG}/images/no_photo.png`};
            return {src: image.url.replace(/(\d+)x(\d+)/, IMG_SIZE)};
          }) : null,
          price_unit: item.price.unit ? item.price.unit : null,
          price_currency: item.price.currency ? item.price.currency : null,
          title: item.title,
          address: item.geo.street ? (item.geo.street.name + (item.geo.house ? `, ${item.geo.house}` : '')) : '',
          metro: item.geo.metro ? item.geo.metro : null,
          area: item.geo.city.name + (item.geo.area ? `, ${item.geo.area.name}` : ''),
          newflat: item.body.new_flat_id === 1,
          type_id: item.type_id,
          description: item.body.description,
          userId: item.user ? item.user.id : null,
          userType: item.user ? item.user.acc_type_name : null,
          s_full: item.body.area,
          s_living: item.body.living_space,
          s_kitchen: item.body.kitchen_space,
          floor: item.body.floor ? `${item.body.floor} этаж ${item.body.floors_total} этажного дома`
            : (item.body.floors_total ? `${item.body.floors_total} этаж${plural(item.body.floors_total, '', 'а', 'ей')}` : null),
          body_bathroom: item.body.bathroom ? item.body.bathroom.name : null,
          body_floor_covering: item.body.floor_covering ? item.body.floor_covering.name : null,
          body_balcony: item.body.floor_covering ? item.body.balcony.name : null,
          location: item.geo.location,
          map: (item.geo.map) ? item.geo.map.url : null,
          favorite: item.favorite === true,
          active: item.f_status === 0,
          call: item.call,
        }
      });
  }

  async getUser(userId) {

    const fetchUrl = `/v1/user/${userId}`;
    return await HttpClient.get(fetchUrl, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;

        return {
          id: item.id,
          url: relative(item.url),
          image: item.image ? item.image : `${BUDUDOMA_IMG}/images/no_photo.png`,
          type: item.acc_type,
          alias: item.acc_type_alias,
          city: item.city,
          date: item.date_create,
          name: item.name,
        }
      });
  }

  async getContacts(userId, objId) {

    const platform = this.platform === "webapp" ? 2 : 3;

    const fetchUrl = `/v1/user/${userId}/contact?obj_id=${objId}&fields=contact&platform=${platform}`;
    return await HttpClient.get(fetchUrl, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;

        return {
          id: item.id,
          phone: item.contact.phone,
          type: item.contact.type,
        }
      });
  }

  async getNewsList(url) {
    return await HttpClient.get(url ? url : `/v1/news?sort=-id`)
      .then(res => {
        if (!res.data.items) {
          return null;
        }

        return [
          _.get(res.data, '_meta.totalCount'),
          _.get(res.data, '_links.next.href'),
          _.map(res.data.items, item => {
            return {
              id: item.id,
              header: item.header,
              litera: item.litera,
              image: item.image.replace(/uploads\/images/, 'c/' + IMG_SIZE),
              group: item.group,
              counter: item.counter,
              url: '/news/' + item.id,
              date: moment(item.datetime_publish).format("DD.MM.YYYY"),
            }
          }),
        ]
      });
  }

  async getNewsPage(id) {

    const fetchUrl = `v1/news/${id}`;
    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;

        if (!item.id) {
          return {
            error: item
          };
        }

        let body = item.body;

        body = body.replace(/<a[^<]+>(.*?)<\/a>/gi, "$1");

        return {
          id: item.id,
          header: item.header,
          litera: item.litera,
          image: item.image,
          body: body,
          counter: item.counter,
          date: moment(item.datetime_publish).format("DD.MM.YYYY"),
        }
      }).catch(error => {
        return {error: error};
      });
  }

  async getTipList() {
    const url = `/v1/tip`;
    return await HttpClient.get(url)
      .then(res => {
        if (!res.data.items) {
          return null;
        }

        return [
          _.get(res.data, '_meta.totalCount'),
          _.get(res.data, '_links.next.href'),
          _.map(res.data.items, item => {
            return {
              id: item.id,
              header: item.header,
              litera: item.litera,
              url: 'tipview/' + item.id,
              group: item.group,
            }
          }),
        ]
      });
  }

  async getTipPage(id) {

    const fetchUrl = `/v1/tip/${id}`;
    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;
        let body = item.body;

        body = body.replace(/<a[^<]+>(.*?)<\/a>/gi, "$1");

        return {
          id: item.id,
          header: item.header,
          litera: item.litera,
          body: body,
          url: item.url,
          group: item.group,
        }
      });
  }

  async getHelpList() {
    const url = `/v1/help`;
    return await HttpClient.get(url)
      .then(res => {
        if (!res.data.items) {
          return null;
        }

        return [
          _.get(res.data, '_meta.totalCount'),
          _.get(res.data, '_links.next.href'),
          _.map(res.data.items, item => {
            return {
              id: item.id,
              header: item.header,
              litera: item.litera,
              url: 'helpview/' + item.id,
              group: item.group,
            }
          }),
        ]
      });
  }

  async getHelpPage(id) {

    const fetchUrl = `/v1/help/${id}`;
    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;
        let body = item.body;

        return {
          id: item.id,
          header: item.header,
          litera: item.litera,
          body: body,
          url: item.url,
          group: item.group,
        }
      });
  }

  async getOwnerList(url, params) {
    params = _.pickBy(params, function (v, k) {
      return _.includes(BUDUDOMA_SEARCH_FIELDS, k) && v != null && v !== 0 && v !== "" && v !== false;
    });

    const args = urlParams(params);
    const fetchUrl = url ? url : `/v1/user?${args}&limit=${LIMIT}`;

    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data.items) {
          return null;
        }

        return [
          _.map(res.data.items, item => {
            return {
              id: item.id,
              name: item.name,
              acc_type: item.acc_type,
              url: '/' + item.acc_type_alias + '/' + item.id,
              city: item.city,
              count: item.count,
              image: item.image ? item.image : `${BUDUDOMA_IMG}/images/no_photo.png`,
              date: item.date_create,
            }
          }),
          _.get(res.data, '_meta.totalCount'),
          _.get(res.data, '_links.next.href'),
        ]
      });
  }

  async getOwnerPage(id, params) {

    const args = urlParams(params);
    const fetchUrl = `/v1/user/${id}?${args}`;
    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;

        if (!item.id) {
          return {
            error: item
          };
        }

        return {
          id: item.id,
          name: item.name,
          acc_type: item.acc_type,
          image: item.image ? item.image.replace(/(\d+)x(\d+)/, IMG_SIZE) : null,
          city: item.city,
          date: item.date_create,
          description: item.description,
          website: item.website,
          trust_date_experience: item.trust.date_experience,
          trust_status_name: item.trust.status.name,
          counters: item.count_type,
        }
      });
  }

  async getEstatePage(params) {

    const fetchUrl = `/v1/estate?alias=` + params.alias + '&city_id=' + params.city_id + '&exact=1';

    return await HttpClient.get(fetchUrl)
      .then(res => {
        if (!res.data) {
          return null;
        }

        const item = res.data;

        if (!item.id) {
          return {
            error: item
          };
        }

        return {
          id: item.id,
          name: item.name,
          address: item.geo.address,
          images: item.photos && item.photos.length ? _.map(item.photos, image => {
            if (typeof image.url === "undefined") return {src: `${BUDUDOMA_IMG}/images/no_photo.png`};
            return {src: image.url.replace(/(\d+)x(\d+)/, IMG_SIZE)};
          }) : [{src: `${BUDUDOMA_IMG}/images/no_photo.png`}],
          location: item.geo.location,
        }
      });
  }

  async addFavorite(id) {
    return await HttpClient.post(`/v1/favorite`, {obj_id: id}, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }
        return true;
      });
  }

  async delFavorite(id) {
    return await HttpClient.delete(`/v1/favorite/${id}`, this.getConfig())
      .then(res => {
        if (!res.data) {
          return null;
        }
        return false;
      });
  }
}

export default new BududomaService();

