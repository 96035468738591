import _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from 'actions/types';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  objectsArray: null,
  facets: null,
  showFilter: false,
  similar: null,
  params: {},
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.INIT:
      return state.merge(action.state.obj || {});
    case types.OBJECT_LOADING:
      return state.merge({
        isLoading: true,
        object: null,
        contacts: null,
        user: null,
        similar: null,
      });
    case types.OBJECT_FETCHED:
      return state.merge({
        isLoading: false,
        object: action.object,
        similar: null,
      });
    case types.CONTACTS_FETCHED:
      return state.merge({
        contacts: action.contacts
      });
    case types.USER_FETCHED:
      return state.merge({
        user: action.user
      });
    case types.OBJECTS_LOADING:
      return state.merge({
        isLoading: true,
      });
    case types.OBJECTS_SIMILAR_LOADING:
      return state.merge({
        similar: null,
      });
    case types.OBJECTS_SIMILAR_FETCHED:
      return state.merge({
        similar: action.items,
      });
    case types.OBJECTS_FETCHED:
      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        facets: action.facets,
        objectsArray: state.objectsArray == null ? action.objectsArray : _.concat(state.objectsArray, action.objectsArray)
      });
    case types.OBJECTS_CLEAR:
      return state.merge({
        totalCount: null,
        nextHref: null,
        hasMore: true,
        facets: null,
        objectsArray: null,
        isLoading: false,
      });
    case types.OBJECTS_SEARCH_PARAMS:
      return state.merge({
        params: action.params,
        nextHref: null,
        hasMore: true,
        facets: null,
        objectsArray: null,
        totalCount: null,
      });
    case types.CITY_SELECTED:
      return state.merge({
        params: {areaId: null}
      })
    case types.OBJECTS_FAVORITE_CHANGED:
      const object = action.item;
      const i = _.findIndex(state.objectsArray, {id: object.id});

      if (i < 0)
        return state.merge({
          object: object
        });

      const objects = [
        ..._.slice(state.objectsArray, 0, i),
        {
          ...state.objectsArray[i],
          favorite: object.favorite
        },
        ..._.slice(state.objectsArray, i + 1)
      ];

      return state.merge({
        object: object,
        objectsArray: objects,
      });
    default:
      return state;
  }
}

// selectors

export function getObjects(state) {
  return state.obj;
}

export function hasMore(state) {
  return state.obj.hasMore;
}

export function getNextHref(state) {
  return state.obj.nextHref;
}
