export const ERROR = 'ERROR';
export const INIT = 'INIT';

// app
export const APP_SET_SMART = 'app.SET_SMART';

// geo
export const CITIES_LOADING = 'geo.CITIES_LOADING';
export const CITIES_FETCHED = 'geo.CITIES_FETCHED';
export const CITY_SELECTED = 'geo.CITY_SELECTED';
export const CITY_FETCHED = 'geo.CITY_FETCHED';
export const LOCATION_LOADING = 'geo.LOCATION_LOADING';
export const LOCATION_FETCHED = 'geo.LOCATION_FETCHED';

// obj
export const OBJECT_LOADING = 'obj.OBJECT_LOADING';
export const OBJECT_FETCHED = 'obj.OBJECT_FETCHED';
export const CONTACTS_FETCHED = 'obj.CONTACTS_FETCHED';
export const USER_FETCHED = 'obj.USER_FETCHED';
export const OBJECTS_LOADING = 'obj.OBJECTS_LOADING';
export const OBJECTS_FETCHED = 'obj.OBJECTS_FETCHED';
export const OBJECTS_CLEAR = 'obj.OBJECTS_CLEAR';
export const OBJECTS_SEARCH_PARAMS = 'obj.OBJECTS_SEARCH_PARAMS';
export const OBJECTS_SIMILAR_LOADING = 'obj.SIMILAR_LOADING';
export const OBJECTS_SIMILAR_FETCHED = 'obj.SIMILAR_FETCHED';
export const OBJECTS_FAVORITE_CHANGED = 'obj.FAVORITE_CHANGED';

// favorites
export const FAVORITES_CLEAR = 'fav.OBJECTS_CLEAR';
export const FAVORITES_LOADING = 'fav.OBJECTS_LOADING';
export const FAVORITES_FETCHED = 'fav.OBJECTS_FETCHED';
export const FAVORITES_CHANGED = 'fav.OBJECTS_CHANGED';
export const FAVORITES_GC = 'fav.OBJECTS_GC';

// history
export const HISTORY_CLEAR = 'history.OBJECTS_CLEAR';
export const HISTORY_LOADING = 'history.OBJECTS_LOADING';
export const HISTORY_FETCHED = 'history.OBJECTS_FETCHED';

// related
export const RELATED_SET = 'related.OBJECTS_SET';
export const RELATED_CLEAR = 'related.OBJECTS_CLEAR';
export const RELATED_LOADING = 'related.OBJECTS_LOADING';
export const RELATED_FETCHED = 'related.OBJECTS_FETCHED';

// news
export const NEWS_PAGE_LOADING = 'news.PAGE_LOADING';
export const NEWS_PAGE_FETCHED = 'news.PAGE_FETCHED';
export const NEWS_LIST_LOADING = 'news.LIST_LOADING';
export const NEWS_LIST_FETCHED = 'news.LIST_FETCHED';

// tip
export const TIP_PAGE_LOADING = 'tip.PAGE_LOADING';
export const TIP_PAGE_FETCHED = 'tip.PAGE_FETCHED';
export const TIP_LIST_LOADING = 'tip.LIST_LOADING';
export const TIP_LIST_FETCHED = 'tip.LIST_FETCHED';

// help
export const HELP_PAGE_LOADING = 'help.PAGE_LOADING';
export const HELP_PAGE_FETCHED = 'help.PAGE_FETCHED';
export const HELP_LIST_LOADING = 'help.LIST_LOADING';
export const HELP_LIST_FETCHED = 'help.LIST_FETCHED';

// owner
export const OWNER_PAGE_LOADING = 'owner.PAGE_LOADING';
export const OWNER_PAGE_FETCHED = 'owner.PAGE_FETCHED';
export const OWNER_SEARCH_PARAMS = 'owner.SEARCH_PARAMS';
export const OWNER_LIST_LOADING = 'owner.LIST_LOADING';
export const OWNER_LIST_FETCHED = 'owner.LIST_FETCHED';
export const OWNER_CLEAR = 'owner.OWNER_CLEAR';
export const OWNER_OBJECTS_LOADING = 'owner.OBJECTS_LOADING';
export const OWNER_OBJECTS_SEARCH_PARAMS = 'owner.OBJECTS_SEARCH_PARAMS';
export const OWNER_OBJECTS_FETCHED = 'owner.OBJECTS_FETCHED';
export const OWNER_OBJECTS_CLEAR = 'owner.OWNER_OBJECTS_CLEAR';

// estate
export const ESTATE_PAGE_LOADING = 'estate.PAGE_LOADING';
export const ESTATE_PAGE_FETCHED = 'estate.PAGE_FETCHED';
