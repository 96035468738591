import React from "react";
import {Route, Switch} from "react-router-dom";

import {ObjCall, ObjDetails, ObjFavorites, ObjHistory, ObjList, ObjMap, ObjRelated, ObjSearch} from "web/screens/Obj";
import {EstateViewPage} from "web/screens/Estate";
import {NewsListPage, NewsViewPage} from "web/screens/News";
import {TipListPage, TipViewPage} from "web/screens/Tip";
import {HelpViewPage} from "web/screens/Help";
import SupportPage from "web/screens/Support";
import {OwnerListPage, OwnerObjList, OwnerViewPage} from "web/screens/Owner";
import {Drawer, TopBar} from 'web/components/Header';
import {DEFAULT_TITLE} from "constants/config";

class Page extends Route {

  updateNavigation = () => {
    const {navigation} = this.props.component;
    document.title = DEFAULT_TITLE + (navigation && navigation.title ? ' - ' + navigation : '');
  }

  componentDidMount = () => {
    this.updateNavigation()
  }
  componentDidUpdate = () => {
    this.updateNavigation()
  }

  render() {
    const {navigation} = this.props.component;
    return <>
      <header>
        {this.props.modal ? <TopBar {...navigation} /> : <Drawer {...navigation} />}
      </header>
      <main>
        <Route {...this.props} />
      </main>
    </>
  }
}

const NotFound = () => {
  throw new URIError('Страница не найдена');
}

const PageRegister = () => (
  <article className="full-page">
    <div>
      <h1>Регистрация</h1>
      <p>
        В мобильной версии на данный момент нет возможности работать с личным кабинетом.
      </p>
      <p>
        Пока мы прилагаем максимум усилий, чтобы это исправить, Вы можете воспользоваться полной версией сайта.
      </p>
      <p className="text-center"><br/><br/><a href="https://lk.bududoma.ru" className="button">Перейти в личный кабинет
        →</a></p>
    </div>
  </article>
)

export default () => (
  <Switch>
    <Page exact path="/:acc_type(agency|developer|rieltor)/:user_id([\d]+)/:property_id/:type_id(sale|rent)/:etc"
          component={OwnerObjList} modal/>
    <Page exact path="/:acc_type(agency|developer|rieltor)/:user_id([\d]+)/:property_id/:type_id(sale|rent)"
          component={OwnerObjList} modal/>
    <Page exact path="/:acc_type(agency|developer|rieltor)/:user_id([\d]+)/:property_id" component={OwnerObjList}
          modal/>
    <Page exact path="/:acc_type(agency|developer|rieltor)/:id([\d]+)" component={OwnerViewPage} modal/>
    <Page exact path="/:acc_type(agency|developer|rieltor)/" component={OwnerListPage}/>

    <Page exact path="/about/tip" component={TipListPage}/>
    <Page exact path="/about/tipview/:id([\d]+)" component={TipViewPage} modal/>
    <Page exact path="/about/:page" component={HelpViewPage} modal/>
    <Page exact path="/about" component={HelpViewPage}/>

    <Page exact path="/registration" component={PageRegister}/>
    <Page exact path="/login" component={PageRegister}/>

    <Page exact path="/news" component={NewsListPage}/>
    <Page exact path="/news/:id([\d]+)" component={NewsViewPage} modal/>

    <Page exact path="/estate/:alias" component={EstateViewPage}/>

    <Page exact path="/search" component={ObjSearch} modal/>
    <Page exact path="/favorite" component={ObjFavorites}/>
    <Page exact path="/history" component={ObjHistory}/>

    <Page exact path="/:property_id/:type_id(sale|rent)/:id([\d]+)" component={ObjDetails} modal/>
    <Page exact path="/:property_id/:type_id(sale|rent)/:id([\d]+)/call" component={ObjCall} modal/>
    <Page exact path="/:property_id/:type_id(sale|rent)/:id([\d]+)/map" component={ObjMap} modal/>
    <Page exact path="/:property_id/:type_id(sale|rent)/:id([\d]+)/related" component={ObjRelated} modal/>

    <Page path="/:property_id(flat|newbuild|commercial|cottage|ground|garage)/:type_id(sale|rent)"
          component={ObjList}/>
    <Page exact path="/:property_id(flat|newbuild|commercial|cottage|ground|garage)" component={ObjList}/>

    <Page exact path="/support" component={SupportPage}/>

    {/*<Route exact path="/" component={Main}/>*/}
    <Page exact path="/" component={ObjList}/>
    <Page component={NotFound}/>
  </Switch>
)
