import _ from "lodash"
import React from 'react'
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import * as dic from "constants/dictionary"
import autoBind from "react-autobind";
import {BUDUDOMA_SEARCH_FIELDS} from "services/bududoma";
import {IoMdCheckmark} from "react-icons/all";

const initialState = _.zipObject(
  BUDUDOMA_SEARCH_FIELDS,
  _.map(BUDUDOMA_SEARCH_FIELDS, () => {
  })
);

export default class SearchForm extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = initialState;
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  };

  componentDidMount() {
    this.setState(
      this.props.params
    )
  }

    handleTypeChange(event) {
        var val = event.currentTarget.querySelector("input").value;
        this.setState({ type_id: val });
    }

  handleChange(e) {

    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value
    })

    const cl = e.target.getAttribute('data-clear');
    if (cl) {
      this.setState({
        [cl]: ''
      })
    }
  }

  handleReset() {
    this.setState(initialState)
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onFilterSubmit(this.state);
  }

  render() {

    const active_sale = this.state.type_id === 'sale' ? 'active' : null;
    const active_rent = this.state.type_id === 'rent' ? 'active' : null;

    return (
      <article className="search-form bottom-padding">
        <Form onSubmit={this.handleSubmit}>

          <FormGroup>
              <div className="text-center">
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                      <label className={`btn btn-primary ${active_sale}`} onClick={this.handleTypeChange}>
                          <input
                              type="radio"
                              name="type_id"
                              value="sale"
                              defaultChecked="1"
                              autoComplete="off"
                          />
                          Продажа
                      </label>
                      <label className={`btn btn-primary ${active_rent}`} onClick={this.handleTypeChange}>
                          <input
                              type="radio"
                              name="type_id"
                              value="rent"
                              autoComplete="off"
                          />{" "}
                          Аренда
                      </label>


                  </div>
              </div>
          </FormGroup>

          <FormGroup>
            <Label for="property_id">Вид недвижимости</Label>
            <Input type="select" name="property_id" id="property_id" value={this.state.property_id}
                   data-clear="object_id" onChange={this.handleChange}>
              <option value="">- любой -</option>
              {_.map(dic.property, (item, id) =>
                <option key={`property_id_${id}`} value={item.alias}>{item.name}</option>
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="object_id">Тип объекта</Label>
            <Input type="select" name="object_id" id="object_id" value={this.state.object_id}
                   onChange={this.handleChange}>
              <option value="">- любой -</option>
              {_.map(dic.object[this.state.property_id], (item, id) =>
                <option key={`object_id_${id}`} value={id}>{item.name}</option>
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="object_id">Районы / деревни</Label>
            <Input type="select" name="area_id" id="area_id" value={this.state.area_id}
                   onChange={this.handleChange}>
              <option value="">- любой -</option>
              {_.map(this.props.city.areas, (item, id) =>
                <option key={`area_id_${id}`} value={item.id}>{item.name}</option>
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="address">Адрес</Label>
            <Input type="search" name="address" id="address" value={this.state.address || ''}
                   onChange={this.handleChange}/>
          </FormGroup>

          <FormGroup>
            <Label for="price_from">Цена</Label>
            <Row form>
              <Col sx={6}>
                <Input type="text" name="price_from" id="price_from" value={this.state.price_from || ''}
                       placeholder="от тыс. руб" onChange={this.handleChange}/>
              </Col>
              <Col sx={6}>
                <Input type="text" name="price_to" id="price_to" value={this.state.price_to || ''}
                       placeholder="до тыс. руб" onChange={this.handleChange}/>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label for="floor_id">Этажность</Label>
            <Input type="select" name="floor_id" id="floor_id" value={this.state.floor_id}
                   onChange={this.handleChange}>
              <option value="">- любой -</option>
              {_.map(dic.floor, (item, id) =>
                  <option key={`floor_id_${id}`} value={id}>{item.name}</option>
              )}
            </Input>
          </FormGroup>

          <FormGroup>
            <div className="form-switches">
              <div className="custom-switch custom-control">
                <Input type="checkbox" id="is_self_seller" name="is_self_seller"
                       className="custom-control-input"
                       checked={this.state.is_self_seller} onChange={this.handleChange}/>
                <label className="custom-control-label" htmlFor="is_self_seller">Только частные</label>
              </div>
            </div>
          </FormGroup>

          <FormGroup>
            <div className="form-switches">
              <div className="custom-switch custom-control">
                <Input type="checkbox" id="is_newbuilder" name="is_newbuilder"
                       className="custom-control-input"
                       checked={this.state.is_newbuilder} onChange={this.handleChange}/>
                <label className="custom-control-label" htmlFor="is_newbuilder">Только новостройки</label>
              </div>
            </div>
          </FormGroup>

          <div className="bottom-fixed">
            {/*<Button type="reset" color="danger" onClick={this.handleReset}>Сбросить</Button>*/}
            <Button type="submit" color="primary"><i><IoMdCheckmark/></i>Применить</Button>
          </div>
        </Form>
      </article>
    );
  }
}

