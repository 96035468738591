import React from "react";
import _ from "lodash";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";

export const FacetList = ({items}) => {

  if (!items || items.length === 1) return null;

  return <div className="facet-list">
    <div>
      {_.map(items, item => {
        return <Link to={item.url} key={item.url}>
            <Button color="primary" outline>{item.header}</Button>
          </Link>
      })}
    </div>
  </div>
}

