import bududomaService from 'services/bududoma';
import * as types from 'actions/types';

export const fetchCities = q => {
  return async (dispatch) => {
    dispatch({type: types.CITIES_LOADING});
    try {
      const citiesArray = await bududomaService.getCities(q);
      dispatch({type: types.CITIES_FETCHED, citiesArray});
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchByLocation = (lat,lon) => {
  return async (dispatch) => {
    dispatch({type: types.LOCATION_LOADING});
    try {
      const citiesArray = await bududomaService.getCities(lat + ',' + lon);
      const city = citiesArray? citiesArray[Object.keys(citiesArray)[0]] : null;
      dispatch({type: types.LOCATION_FETCHED, city});
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchCity = id => {
  return async (dispatch) => {
    try {
      const city = await bududomaService.getCity(id);
      dispatch({type: types.CITY_FETCHED, city});
    } catch (error) {
      console.error(error);
    }
  };
};

export function selectCity(cityId) {
  return ({type: types.CITY_SELECTED, cityId});
}
