import bududomaService from 'services/bududoma';
import * as types from 'actions/types';
import * as newsSelectors from "reducers/newsReducer";

export const fetchNewsPage = (id) => {
  return async (dispatch) => {
    dispatch({type: types.NEWS_PAGE_LOADING});
    const page = await bududomaService.getNewsPage(id);
    dispatch({type: types.NEWS_PAGE_FETCHED, page});
  };
}

export const fetchNewsList = () => {
  return async (dispatch, getState) => {
    dispatch({type: types.NEWS_LIST_LOADING});

    const url = newsSelectors.getNextHref(getState());

    const [totalCount, nextHref, newsList] = await bududomaService.getNewsList(url);
    dispatch({type: types.NEWS_LIST_FETCHED, totalCount, nextHref, newsList});
  };
};
