import * as types from 'actions/types';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  ownerList: null,
  page: null,
});

export default function reduce(state = initialState, action = {}) {

  switch (action.type) {

    case types.ESTATE_PAGE_LOADING:
      return state.merge({
        isLoading: true,
        page: null,
      });

    case types.ESTATE_PAGE_FETCHED:

      return state.merge({
        isLoading: false,
        page: action.page
      });

    default:
      return state;
  }

}

// selectors

export function getEstate(state) {
  return state.estate;
}

