import _ from "lodash"
import {applyMiddleware, createStore} from 'redux'
import Immutable from "seamless-immutable"
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import reducers from 'reducers'
import {SENTRY_DSN} from "constants/config"
import * as dic from "constants/dictionary"
import {uuid} from "utils"
import Cookie from "services/cookie"
import bududomaService from "services/bududoma"
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

const DEV = process.env.NODE_ENV === 'development';
if (!DEV) {
  Sentry.init({dsn: SENTRY_DSN});
}

let initState = Immutable({});

// local storage
const persistedState = typeof localStorage !== 'undefined' ? localStorage.getItem('reduxState') : null;
if (persistedState) {
  initState = initState.merge(JSON.parse(persistedState));
}

// bootstrap from php
if (typeof window !== 'undefined' && window.hasOwnProperty('_bootstrap')) {
  const {geoId, cityName, params} = window._bootstrap;

  initState = initState.merge({
    geo: {
      geoId: geoId === 0 ? null : geoId,
      cityName: cityName,
      mode: 'redirect'
    },
  });

  if (params !== null) {

    if (_.isInteger(params['type_id'])) params['type_id'] = dic.type[params['type_id']]['alias'];
    if (_.isInteger(params['property_id'])) params['property_id'] = dic.property[params['property_id']]['alias'];

    initState = initState.merge({
      obj: {
        params: bududomaService.sanitizeParams(params)
      },
    });
  }
}

let did = null;

// device id
if (DEV) {
  if (initState.app == null || initState.app.uuid == null) {
    did = '2' + uuid().substr(1); // 2 - webapp
  } else {
    did = initState.app.uuid
  }
} else {

  did = Cookie.getCookie('_bd_did');
  if (did == null) {

    did = '2' + uuid().substr(1); // 2 - webapp

    let d = new Date();
    d.setTime(d.getTime() + (3600 * 24 * 1000));
    Cookie.setCookie("_bd_did", did, {
      path: "/",
      expires: d,
      domain: '.bududoma.ru' + process.env.REACT_APP_ENV_TYPE
    });
  }
}

initState = initState.merge({
  app: {
    uuid: did
  }
});

bududomaService.platform = 'webapp';
bududomaService.uuid = did;

const middleware = [
  thunkMiddleware,
  createSentryMiddleware(Sentry),
  DEV && createLogger({collapsed: true}),
].filter(Boolean);

const store = createStore(reducers, initState, applyMiddleware(...middleware));

// autosave state
store.subscribe(() => {
  const {geo, obj, app} = store.getState();
  localStorage.setItem('reduxState', JSON.stringify({
    geo: {geoId: geo.geoId, cityName: geo.cityName},
    obj: {params: obj.params},
    app: {value: app.value, time: app.time, uuid: app.uuid}
  }))
});

export default store;
