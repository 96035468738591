import Immutable from 'seamless-immutable';
import * as types from 'actions/types';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  list: null,
  page: null,
});

export default function reduce(state = initialState, action = {}) {

  switch (action.type) {

    case types.TIP_LIST_LOADING:
      return state.merge({
        isLoading: true,
        tipList: null,
      });

    case types.TIP_LIST_FETCHED:

      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        tipList: action.tipList,
      });

    case types.TIP_PAGE_LOADING:
      return state.merge({
        isLoading: true,
        page: null,
      });

    case types.TIP_PAGE_FETCHED:
      return state.merge({
        isLoading: false,
        page: action.page
      });

    default:
      return state;
  }

}

// selectors

export function getTip(state) {
  return state.tip;
}
