import React from "react";
import {connect} from "react-redux";
import * as selectors from "reducers/ownerObjReducer";
import * as ownerActions from "actions/owner";
import InfiniteScroll from "react-infinite-scroller";
import {Loader} from "web/components";
import {ObjList} from "../Obj/components/ObjList";
import {numberFormat} from "utils";
import * as actionTypes from "actions/types";


function mapStateToProps(state) {
  const obj = selectors.getState(state);
  return {
    items: obj.items,
    hasMore: obj.items ? obj.hasMore : true,
    totalCount: obj.totalCount || 0,
    loading: obj.isLoading,
    params: obj.params,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchObjects: () => dispatch(ownerActions.fetchObjects()),
  setSearchParams: params => dispatch(ownerActions.setSearchParams(params)),
  clearData: () => dispatch({type: actionTypes.OWNER_OBJECTS_CLEAR}),
})

class OwnerObjList extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  join({property_id, user_id, acc_type}) {
    return acc_type + ':' + user_id + ':' + property_id
  }

  componentWillMount() {
    if (this.join(this.props.params) !== this.join(this.props.match.params)) this.props.clearData();
    this.props.setSearchParams(this.props.match.params);
  }

  render() {
    return <InfiniteScroll
      hasMore={this.props.hasMore}
      loadMore={this.props.fetchObjects}
      loader={<Loader key={0} height={this.props.items ? '4vh' : null}/>}
    >
      {this.props.items ?
        <div className="obj-result">Найдено объектов: {numberFormat(this.props.totalCount)}</div> : null}
      <ObjList items={this.props.items} onFavoriteClick={this.onFavoriteClick}/>
    </InfiniteScroll>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerObjList);
