import React from 'react'
import PropTypes from 'prop-types'

export default function IndicatorCounter(props) {
  const wrapperStyle = {
    position: 'absolute',
    zIndex: '100',
    top: '10px',
    left: '10px',
    textAlign: 'right',
    fontSize: '.8rem',
    padding: '.1rem .5rem',
    backgroundColor: 'rgba(255,255,255,0.7)',
    borderRadius: '.2rem',
  }

  if (props.total < 2) {
    return <div style={wrapperStyle}/>
  } else {
    return (
      <div style={wrapperStyle}>{props.index + 1} / {props.total}</div>
    )
  }
}

IndicatorCounter.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}
