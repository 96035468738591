import './styles.css';
import React from 'react'
import {ListRow, ListView, Loader} from "web/components";
import _ from "lodash";
import autoBind from "react-autobind";
// import { geolocated } from "react-geolocated";

 class Geo extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.props.clearData();
    this.props.fetchCities();
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0)
  };

  _renderRow(id, item) {
    return (
      <ListRow
        className="city-select"
        rowId={item.geoId}
        onClick={this._onRowClick}>
        <span className="city-title">{item.title}</span><span className="float-right">{item.cnt}</span>
      </ListRow>
    )
  }

  _onRowClick(geoId) {
    this.props.selectCity(geoId);
    if (this.props.mode !== 'redirect') this.props.history.push('/');
  }

  render() {

    if (this.props.citiesArray == null) {
      return <Loader/>;
    }

    const cities = _.orderBy(this.props.citiesArray, ['cnt'], ['desc']);
    const mainCities = _.filter(cities, 'general');
    const regions = _.groupBy(cities, 'region');

    let k = 0;
    return (
      <div className="container">
        <div key={k} className="city-general">
          <h1 className="text-center">Выберите город</h1>
          <ListView
            rowsIdArray={_.keys(mainCities)}
            rowsById={mainCities}
            renderRow={this._renderRow}/>
        </div>

        {_.map(regions, (cities, region) => {
          return (
            <div key={++k}>
              <h2>{region}</h2>
              <ListView
                rowsIdArray={_.keys(cities)}
                rowsById={cities}
                renderRow={this._renderRow}/>
            </div>
          );
        })}

      </div>
    )
  }
}

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(Geo);

export default Geo;
