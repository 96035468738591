import React from "react";
import {Col, Row} from "reactstrap";
import convert from "htmr";

const UserInfo = ({user}) => {

  if (!user) return null;

  return (
    <Row className="userinfo">
      <Col xs="3">
        <figure className="avatar rounded-circle"
                style={{backgroundImage: `url(${user.image})`}}/>
      </Col>
      <Col xs="9">
        <div>{user.type}</div>
        <div
          className="name">{user.alias === 'agency' ? convert("&laquo;"
          + user.name + "&raquo;") : user.name}</div>
        {user.date ? <div className="dt">На сайте {user.date}</div> : null}
      </Col>
    </Row>
  );
}

export default UserInfo
