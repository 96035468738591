import './styles.css'

import React from "react";
import autoBind from "react-autobind";
import * as objSelectors from "reducers/objReducer";
import * as geoSelectors from "reducers/geoReducer";
import * as objActions from "actions/obj";
import * as geoActions from "actions/geo";
import * as actionTypes from "actions/types";
import {connect} from "react-redux";
import SearchForm from "./SearchForm";


class ObjSearchContainer extends React.Component {

  static navigation = {
    header: {
      title: 'Параметры поиска'
    },
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.props.fetchCity(this.props.geoId);
  }

  handleFilterSubmit(params) {
    this.props.setSearchParams(params);
    this.props.clearData();
    this.props.fetchObjects();

    if (this.props.navigation) this.props.navigation.goBack();

    //todo генерировать чпу для поиска
    if (this.props.history) this.props.history.push('/');
  }

  render() {
    return <SearchForm {...this.props}
                       onFilterSubmit={this.handleFilterSubmit}
                       city={{
                         id: this.props.geoId,
                         name: this.props.selectedCity,
                         areas: this.props.areas
                       }}
    />
  }
}

function mapStateToProps(state) {

  const obj = objSelectors.getObjects(state);
  const geo = geoSelectors.getGeo(state);

  return {
    params: obj.params,
    selectedCity: geo.cityName,
    citiesArray: geoSelectors.getCities(state),
    geoId: geoSelectors.getSelectedCityId(state),
    areas: geoSelectors.getAreas(state),
    isCitySelected: geoSelectors.isCitySelected(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCity: id => dispatch(geoActions.fetchCity(id)),
  setSearchParams: (params) => dispatch({type: actionTypes.OBJECTS_SEARCH_PARAMS, params}),
  fetchCities: () => dispatch(geoActions.fetchCities()),
  clearData: () => dispatch(objActions.clearData()),
  fetchObjects: () => dispatch(objActions.fetchObjects()),
  selectCity: geoId => dispatch(geoActions.selectCity(geoId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjSearchContainer);
