import './styles.css'

import _ from "lodash";
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import InfiniteScroll from 'react-infinite-scroller'

import * as ownerSelectors from "reducers/ownerReducer";
import * as ownerActions from "actions/owner";

import {Loader} from "web/components";
import {Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import * as actionTypes from "actions/types";
import numberFormat from "utils/numberFormat";

function mapStateToProps(state) {
  const owner = ownerSelectors.getOwner(state);
  return {
    items: owner.items,
    hasMore: owner.items ? owner.hasMore : true,
    totalCount: owner.totalCount || 0,
    loading: owner.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchOwnerList: () => dispatch(ownerActions.fetchOwnerList()),
  setParams: params => dispatch(ownerActions.setParams(params)),
  clearData: () => dispatch({type: actionTypes.OWNER_CLEAR}),
})

class OwnerListPage extends Component {

  componentWillMount() {
    this.props.setParams(this.props.match.params);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {params} = nextProps.match;

    this.props.setParams(params);

    if (params.acc_type !== this.props.match.params.acc_type) {
      this.props.clearData();
      window.scrollTo(0, 0);
    }
  }

  renderItems() {

    if (!this.props.items) return null;

    return _.map(this.props.items, item => {
      return (
        <ListGroupItem key={item.id}>
          <Link to={item.url}>
            <Row>
              <Col xs="2">
                <figure className="avatar-small rounded-circle"
                        style={{backgroundImage: `url(${item.image})`}}/>
              </Col>
              <Col xs="7">
                <div className="name">{item.name}</div>
                <div className="subname">{item.city}</div>
                {item.date ? <div className="dt">На сайте {item.date}</div> : null}
              </Col>
              <Col xs="3"><div className="counter">{numberFormat(item.count)}</div></Col>
            </Row>
          </Link>
        </ListGroupItem>
      )
    })
  }

  renderTitle() {
    const {acc_type} = this.props.match.params;

    switch (acc_type) {
      case 'agency':
        return 'Агентства недвижимости';
      case 'rieltor':
        return 'Риэлторы';
      case 'developer':
        return 'Застройщики';
      default:
        return null;
    }
  }

  render() {

    return (
      <InfiniteScroll
        hasMore={this.props.hasMore}
        loadMore={this.props.fetchOwnerList}
        loader={<Loader key={0} height={this.props.items ? '2vh' : null}/>}
      >
        <h1 className="text-left">{this.renderTitle()}</h1>
        <ListGroup className="owner-list">
          {this.renderItems()}
        </ListGroup>
      </InfiniteScroll>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerListPage);
