import _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from 'actions/types';

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  items: null,
});

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.HISTORY_LOADING:
      return state.merge({
        isLoading: true,
      });
    case types.HISTORY_FETCHED:
      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        items: state.items == null ? action.items : _.concat(state.items, action.items)
      });
    case types.HISTORY_CLEAR:
      return state.merge({
        totalCount: null,
        nextHref: null,
        hasMore: true,
        items: [],
        isLoading: false,
      });
    default:
      return state;
  }
}

// selectors

export function getHistory(state) {
  return state.history;
}

export function hasMore(state) {
  return state.history.hasMore;
}

export function getNextHref(state) {
  return state.history.nextHref;
}

export function isLoading(state) {
  return state.history.isLoading;
}
