import React, {Component} from 'react'
import {connect} from "react-redux";
import * as historyActions from "actions/history";
import {Loader} from "web/components";
import InfiniteScroll from "react-infinite-scroller";
import * as historySelectors from "reducers/historyReducer";
import {ObjList} from "./components/ObjList";

class ObjHistory extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <InfiniteScroll
      hasMore={this.props.hasMore}
      loadMore={this.props.fetchHistory}
      loader={<Loader key={0} height={this.props.totalCount ? '2vh' : null}/>}
    >
      <h1 className="text-left">История просмотров</h1>
      <ObjList items={this.props.items}/>
      {this.props.totalCount === undefined && this.props.items.length === 0 ?
        <div className="content"><p>Список пуст</p></div> : ''}
    </InfiniteScroll>
  }
}

function mapStateToProps(state) {
  const history = historySelectors.getHistory(state);
  return {
    hasMore: history.items ? history.hasMore : true,
    totalCount: history.totalCount,
    items: history.items,
    isLoading: history.isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchHistory: () => dispatch(historyActions.fetchHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjHistory);
