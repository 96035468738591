import './styles.css';

import React from 'react';
import {Navbar, NavLink} from "reactstrap";
import {NavLink as RNavLink} from "react-router-dom";
import {isIOS} from 'react-device-detect';
import {IoIosArrowBack, IoMdArrowBack} from "react-icons/all";
import history from 'store/history'

class TopBar extends React.Component {

  componentDidMount() {
    window.prevScrollpos = window.pageYOffset;
    window.addEventListener('scroll', this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll);
  }

  _handleScroll = () => {

    let prevScrollpos = window.prevScrollpos;

    const maxScroll = document.body.clientHeight - window.innerHeight;
    let currentScrollPos = window.pageYOffset;
    if (
      (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll)
      || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
      || (prevScrollpos <= 0 && currentScrollPos <= 0)
      || currentScrollPos < 200
    ) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-5.0rem"; // adjustable based your need
    }
    window.prevScrollpos = currentScrollPos;
  };


  render() {
    const {header} = this.props;

    return (
      <Navbar id="navbar" color="faded" fixed="top" light>
        <NavLink exact tag={RNavLink} to="#" onClick={() => history.goBack()}>{isIOS ? <IoIosArrowBack/> :
          <IoMdArrowBack/>}</NavLink>
        {header && header.title ? <div>{header.title}</div> : null}
        <NavLink></NavLink>
      </Navbar>
    )
  }
}

export default TopBar;
