import React, {Component} from 'react';
import {connect} from "react-redux";
import convert from 'htmr';

import * as tipActions from "actions/tip";
import * as tipSelectors from "reducers/tipReducer";
import {Gallery, Loader} from "web/components";

class TipViewPage extends Component {

  componentWillMount() {
    const {match} = this.props;
    this.props.fetchPage(match.params.id);
  }

  render() {

    if (!this.props.page) {
      return <Loader/>;
    }

    const {page} = this.props;

    const transform = {
      div: (node, props, children) => {

        if (node.className === 'fotorama') {

          const items = node.children.map(i => {
            return {src: i.props.src};
          });

          return <Gallery items={items}/>;
        }
        return <div {...props}>{children}</div>;
      },
    };

    return <article className="tip">
      <h1>{page.header}</h1>
      <div>{convert(page.body, {transform})}</div>
    </article>;
  }
}

function mapStateToProps(state) {
  const tip = tipSelectors.getTip(state);
  return {
    page: tip.page,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPage: id => dispatch(tipActions.fetchTip(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TipViewPage);
