import './styles.css'

import _ from "lodash";
import React, {Component} from 'react';
import {connect} from "react-redux";
import * as tipActions from "actions/tip";
import * as tipSelectors from "reducers/tipReducer";
import InfiniteScroll from 'react-infinite-scroller'
import {Link} from "react-router-dom";
import {Loader} from "web/components";


class TipListPage extends Component {

  renderItems() {

    return _.map(this.props.tipList, item => {
      return (
        <li className="tips-item" key={item.id}>
          <Link to={item.url}>
            <div>
              <span className="">{item.header}</span>
            </div>
          </Link>
        </li>
      )
    })
  }

  render() {
    return (
      <InfiniteScroll
        hasMore={this.props.hasMore}
        loadMore={this.props.fetchTipList}
        loader={this.props.loading ? <Loader key={0}/> : ''}
        threshold={0}
      >
        <h1 className="text-left">Советы</h1>
        <ul className="tips-list">
          {this.renderItems()}
        </ul>
      </InfiniteScroll>
    )
  }
}

function mapStateToProps(state) {
  const tip = tipSelectors.getTip(state);
  return {
    tipList: tip.tipList,
    hasMore: tip.hasMore,
    totalCount: tip.totalCount || 0,
    loading: !tip.tipList,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchTipList: () => dispatch(tipActions.fetchTipList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TipListPage);
