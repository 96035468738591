import React from "react";

const StaticMap = ({location, url, onClick}) => {

  if (url)
    return (
      <figure style={{backgroundImage: `url(${url})`, height: "30vh", width: "100%"}} alt="" onClick={onClick}/>
    );
}

export default StaticMap
