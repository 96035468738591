import React from 'react';
import {DynamicMap} from 'web/components';

class ObjMap extends React.Component {

  render() {
    const {location} = this.props;
    if (!location.state) return null;
    return <DynamicMap location={location.state.loc}/>
  }
}

export default ObjMap;
