import * as types from 'actions/types';
import Immutable from 'seamless-immutable';
import _ from "lodash";

const initialState = Immutable({
  totalCount: null,
  nextHref: null,
  hasMore: true,
  isLoading: false,
  items: null,
  page: null,
  params: {},
});

export default function reduce(state = initialState, action = {}) {

  switch (action.type) {

    case types.OWNER_SEARCH_PARAMS:
      if (state.params.city_id !== action.params.city_id || state.params.acc_type !== action.params.acc_type) {
        return state.merge({
          params: action.params,
          items: null,
          totalCount: null,
          nextHref: null,
        });
      }

      return state.merge({
        params: action.params,
      });

    case types.OWNER_LIST_LOADING:
      return state.merge({
        isLoading: true,
        totalCount: null,
        nextHref: null,
        hasMore: true,
      });

    case types.OWNER_LIST_FETCHED:
      const items = state.items ? _.concat(state.items, action.items) : action.items;
      return state.merge({
        totalCount: action.totalCount,
        nextHref: action.nextHref,
        hasMore: action.nextHref != null,
        isLoading: false,
        items: items,
      });

    case types.OWNER_CLEAR:
      return state.merge({
        totalCount: null,
        nextHref: null,
        hasMore: true,
        items: null,
        isLoading: false,
      });

    case types.OWNER_PAGE_LOADING:
      return state.merge({
        isLoading: true,
      });

    case types.OWNER_PAGE_FETCHED:
      return state.merge({
        isLoading: false,
        page: action.page
      });

    default:
      return state;
  }

}

// selectors

export function getOwner(state) {
  return state.owner;
}
