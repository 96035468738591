import React from 'react';
import {connect} from "react-redux";
import * as objSelectors from "reducers/objReducer";
import * as objActions from "actions/obj";
import autoBind from "react-autobind";
import {event} from "services/analytics";
import UserInfo from "./components/UserInfo";
import convert from "htmr";
import {Loader} from "web/components";
import history from 'store/history'
import {isIOS} from 'react-device-detect';
import {IoMdCall, IoIosCall} from "react-icons/all";

function mapStateToProps(state) {
  const obj = objSelectors.getObjects(state);
  return {
    item: obj.object,
    contacts: obj.contacts,
    user: obj.user,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => ({
  fetchObject: id => dispatch(objActions.fetchObject(id)),
  fetchContacts: (userId, objId) => dispatch(objActions.fetchContacts(userId, objId)),
});

class ObjCall extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    const {user, item} = this.props;
    if (!item || !user) {
      const url = this.props.match.url.replace('/call', '');
      return history.replace(url);
    }

    event('obj_click_expand', `${item.id}`);
    this.props.fetchContacts(user.id, item.id);
  }

  _makeCall() {
    const {contacts} = this.props;

    const number = contacts.phone.replace(/[^+\d]/g, '');
    window.location.href = 'tel:' + number.replace(/[^+\d]/g, '');
    event('obj_make_call', `${this.props.item.id}`);
  }

  renderContacts() {
    const {contacts} = this.props;

    if (!contacts) return <div>
      <Loader height="10vh"/>
      <p className="text-center">Загрузка контактов...</p>
    </div>

    if (contacts.type === "callback") return <div className="text-center">
      <div>Будет осуществлен<br/>бесплатный звонок на номер</div>
      <div className="contacts-phone">{contacts.phone}</div>
      <p className="disclaimer">
        Не отходите далеко!<br/>
        Мы перезвоним через несколько секунд...
      </p>
    </div>

    if (contacts.type === "direct") return <div className="text-center">
      <div>Прямой номер для контакта</div>
      <div className="contacts-phone" onClick={this._makeCall}>{contacts.phone}</div>
      <button className="button-big" onClick={this._makeCall}><i>{isIOS ? <IoIosCall/> : <IoMdCall/>}</i></button>
      <p className="disclaimer">
        Пожалуйста, уведомите абонента,<br/>
        что вы нашли объявление на сайте<br/>
        БудуДома.ру
      </p>
    </div>

    return <div className="text-center">
      <div>Временный номер для контакта<br/>актуален в течение 15 минут</div>
      <div className="contacts-phone" onClick={this._makeCall}>{contacts.phone}</div>
      <button className="button-big" onClick={this._makeCall}><i>{isIOS ? <IoIosCall/> : <IoMdCall/>}</i></button>
      <p className="disclaimer">
        Этот номер защищен.<br/>
        SMS и мессенджеры недоступны.<br/>
        Вы можете уточнить прямые контакты<br/>во время разговора.
      </p>
    </div>
  }

  render() {
    const {item, user} = this.props;

    if (!item) return null;

    return (
      <article className="screen-call">
        <div className="obj">
          <div className="title">{convert(item.title)}</div>
          <div className="address">{item.area}, {item.address}</div>
        </div>
        <hr/>
        <UserInfo user={user}/>
        <hr/>
        {this.renderContacts()}
      </article>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjCall);
