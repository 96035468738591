import './styles.css';

import React from 'react';
import {connect} from "react-redux";
import * as objSelectors from "reducers/objReducer";
import * as objActions from "actions/obj";

import {event} from "services/analytics";
import {Link} from "react-router-dom";
import convert from "htmr";
import {Gallery, Loader, StaticMap} from "web/components";
import FavIcon from "./components/FavIcon";
import SwipeList from "./components/SwipeList";
import UserInfo from "./components/UserInfo";
import {IoIosCall, IoMdCall} from "react-icons/all";
import {isIOS} from "react-device-detect";
import Carousel, {Modal, ModalGateway} from "react-images";

const IMG_SIZE = 'r/720x1280';

function mapStateToProps(state) {
  const obj = objSelectors.getObjects(state);
  return {
    item: obj.object,
    contacts: obj.contacts,
    user: obj.user,
    similar: obj.similar,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => ({
  fetchObject: id => dispatch(objActions.fetchObject(id)),
  fetchContacts: (userId, objId) => dispatch(objActions.fetchContacts(userId, objId)),
  addFavorite: item => dispatch(objActions.addFavorite(item)),
  delFavorite: item => dispatch(objActions.delFavorite(item)),
})


class ObjDetailsContainer extends React.Component {

  state = {
    photoIndex: 0,
    modalIsOpen: false,
  };

  componentWillMount() {
    this._loadData(this.props.match.params.id);
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps) {
    const id = nextProps.match.params.id;
    if (id !== this.props.match.params.id) {
      this._loadData(id)
    }
  }

  _loadData(id) {
    this.props.fetchObject(id);
  }

  onClickSimilar(item) {
    event('obj_click_similar', `${item.id}`);
  }

  onClickFavorite(item) {
    if (item.favorite === true) {
      this.props.delFavorite(item)
    } else {
      this.props.addFavorite(item);
    }
  }

  toggleModal = () => {
    this.setState(state => ({modalIsOpen: !state.modalIsOpen}));
  }

  _renderUser() {

    const {user} = this.props;

    return (<div className="contacts">
        <h2>Контактные данные</h2>
        {user ? <Link to={user.url}>
          <UserInfo user={user}/>
        </Link> : <Loader height="15vh"/>}
      </div>
    );
  }

  _renderCallButton() {
    const {item} = this.props;
    return (
      <div className="bottom-fixed">
        <Link to={`${item.url}/call`}>
          <button><i>{isIOS ? <IoIosCall/> : <IoMdCall/>}</i>ПОЗВОНИТЬ</button>
        </Link>
      </div>
    );
  }

  _renderFavoriteButton() {
    const {item} = this.props;
    return (
      <div className="button button-favorite">
        {item.favorite === true ? <button onClick={() => this.props.delFavorite(item)}>Убрать из избранного</button>
          : <button onClick={() => this.props.addFavorite(item)}>Добавить в избранное</button>}
      </div>
    );
  }

  _renderComplainButton() {
    return (
      <div className="button button-complain">
        <button>Пожаловаться на объявление</button>
      </div>
    );
  }

  renderMetro() {
    const {metro} = this.props.item;

    return metro.map(i => {
      return <span className="metro-icon"
                   key={i.alias}>{i.name} ({i.distance > 1 ? parseFloat(i.distance).toFixed(1) + ' км' : Math.round(i.distance * 1000) + ' м'})</span>
    });
  }

  render() {

    if (!this.props.item) {
      return <Loader/>;
    }

    const {item} = this.props;
    const {photoIndex, modalIsOpen} = this.state;

    event('obj_view', `${item.id}`);

    return (<div className="obj bottom-padding">
      <div className="obj-photo">
        {item.images ? <Gallery images={item.images}
                                onClick={({index}) => this.setState({photoIndex: index, modalIsOpen: true})}/> : ''}
      </div>
      <FavIcon active={item.favorite} onClick={() => this.onClickFavorite(item)}/>
      <div className="obj-description">
        <span className="price-reg">{item.price}</span>
        {item.price_sq ? <span className="price-sq">{item.price_sq}</span> : null}
        <div className="title">{convert(item.title)}</div>
        {item.newflat ? <div className="newflat">Новостройка</div> : null}
        <div className="address">{item.area}, {item.address}</div>
        {item.metro ? <div className="metro">{this.renderMetro()}</div> : null}

        <h2>Площадь</h2>
        {item.s_full ? <div>Общая: {item.s_full} м²</div> : null}
        {item.s_living ? <div>Жилая: {item.s_living} м²</div> : null}
        {item.s_kitchen ? <div>Кухня: {item.s_kitchen} м²</div> : null}

        {item.floor ? <h2>Характеристики</h2> : null}
        {item.floor ? <div><strong>Этажность:</strong> {item.floor}</div> : null}
        {item.body_bathroom ? <div><strong>Санузел:</strong> {item.body_bathroom}</div> : null}
        {item.body_floor_covering ? <div><strong>Покрытие пола:</strong> {item.body_floor_covering}</div> : null}
        {item.body_balcony ? <div><strong>Балкон:</strong> {item.body_balcony}</div> : null}

        {item.description ? <div><h2>Дополнительная информация</h2>
          <div className="description"><p>{convert(item.description)}</p></div>
        </div> : null}

        {item.map ? <Link to={{
          pathname: item.url + "/map", state: {loc: item.location}
        }}><StaticMap url={item.map}/></Link> : null}
        {/*<DynamicMap location={item.location}/>*/}

        {item.userId ? this._renderUser() : null}

        {item.call ? <div className="notice">{item.call}</div> : null}
        {item.userId ? this._renderCallButton() : null}
        {/*{this._renderFavoriteButton()}*/}
        {/*{this._renderComplainButton()}*/}

        <div className="etc">Обновлено: {item.dt_update}<br/>Номер: {item.id}, лот: {item.original_id}</div>
      </div>

      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal}>
            <Carousel
              currentIndex={photoIndex}
              components={{Footer: null}}
              views={item.images.map(image => {
                return {src: image.src.replace(/c\/(\d+)x(\d+)/, IMG_SIZE)}
              })}/>
          </Modal>
        ) : null}
      </ModalGateway>

      {this.props.similar ? <><Link to={item.url + '/related'}><h2 style={{marginLeft: "1rem"}}>Похожие объявления</h2></Link>
        <SwipeList items={this.props.similar} onClick={this.onClickSimilar}/></> : null}
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjDetailsContainer);
