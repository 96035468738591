import './styles.css';

import _ from "lodash";
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import InfiniteScroll from 'react-infinite-scroller'
import LazyLoad from 'react-lazyload';

import * as newsSelectors from "reducers/newsReducer";
import * as newsActions from "actions/news";

import {Loader} from "web/components";
import {Style} from "radium";

class NewsListPage extends Component {

  renderItems() {

    return _.map(this.props.newsList, item => {
      return (
        <li className="news-item" key={item.id}>
          <Link to={item.url}>
            <LazyLoad height="30vh" offset={500}>
              <figure style={{backgroundImage: `url(${item.image})`}} height="30vh" width="100%" alt=""/>
            </LazyLoad>
            <div className="content">
              <div className="news-title">{item.header}</div>
              <div className="news-info">{item.date} / {item.counter}
              </div>
            </div>
          </Link>
        </li>
      )
    })
  }

  render() {
    return (
      <InfiniteScroll
        hasMore={this.props.hasMore}
        loadMore={this.props.fetchNewsList}
        loader={this.props.loading ? <Loader key={0}/> : ''}
        threshold={500}
      >
        <h1 className="text-left">Новости</h1>
        <ul className="news-list">
          <Style
            scopeSelector=".news-list"
            rules={{
              a: {
                color: '#444'
              },
              'a:visited': {
                color: '#551A8B'
              },
            }}/>
          {this.renderItems()}
        </ul>
      </InfiniteScroll>
    )
  }
}

function mapStateToProps(state) {
  const news = newsSelectors.getNews(state);
  return {
    newsList: news.newsList,
    hasMore: news.newsList ? newsSelectors.hasMore(state) : true,
    totalCount: news.totalCount || 0,
    loading: !news.newsList,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchNewsList: () => dispatch(newsActions.fetchNewsList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsListPage);
