import './styles.css'

import React, {Component} from 'react';
import {connect} from "react-redux";
import * as helpActions from "actions/help";
import * as helpSelectors from "reducers/helpReducer";
import {Loader} from "web/components";
import InfiniteScroll from 'react-infinite-scroller'

import _ from "lodash";
import {Link} from "react-router-dom";


class HelpListPage extends Component {

  componentWillMount() {
    this.props.fetchHelpList();
  }

  renderItems() {

    return _.map(this.props.helpList, item => {
      return (
        <li className="tips-item" key={item.id}>
          <Link to={item.url}>
            <div>
              <span className="">{item.header}</span>
            </div>
          </Link>
        </li>
      )
    })
  }

  render() {
    return (
      <InfiniteScroll
        hasMore={this.props.hasMore}
        loadMore={this.props.fetchHelpList}
        loader={this.props.loading ? <Loader key={0}/> : ''}
        threshold={500}
      >
        <h1 className="text-left">Советы</h1>
        <ul className="tips-list">
          {this.renderItems()}
        </ul>
      </InfiniteScroll>
    )
  }
}

function mapStateToProps(state) {
  const help = helpSelectors.getHelp(state);
  return {
    helpList: help.helpList,
    hasMore: help.hasMore,
    totalCount: help.totalCount || 0,
    loading: !help.helpList,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchHelpList: () => dispatch(helpActions.fetchHelpList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpListPage);
