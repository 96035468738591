import Immutable from 'seamless-immutable';
import * as types from "actions/types";

const initialState = Immutable({
  token: null,
  uuid: null,
  error: null,
});

export default function reduce(state = initialState, action = {}) {

  switch (action.type) {
    case types.INIT:
      return state.merge(action.state.app || {});
    case types.ERROR:
      return state.merge({
        error: action.error
      });
    case types.APP_SET_SMART:
      return state.merge({
        value: action.value,
        time: new Date().getTime()
      });
    default:
      return state;
  }
}
