import Immutable from 'seamless-immutable'
import * as types from 'actions/types'

const initialState = Immutable({
  citiesArray: {},
  default: 641,
  geoId: null,
  cityName: null,
  city: null,
  domain: null,
  loading: false,
});

export default function reduce(state = initialState, action = {}) {

  switch (action.type) {
    case types.INIT:
      return state.merge(action.state.geo);
    case types.CITIES_LOADING:
      return state.merge({
        loading: true,
      });
    case types.CITIES_FETCHED:
      return state.merge({
        citiesArray: action.citiesArray,
        loading: false,
      });
    case types.CITY_FETCHED:
      return state.merge({
        city: action.city
      });
    case types.LOCATION_LOADING:
      return state.merge({
        city: null
      });
    case types.LOCATION_FETCHED:
      return state.merge({
        city: action.city
      });
    case types.CITY_SELECTED:

      // redirect if bootstrapped
      let domain = action.cityId ? state.citiesArray[action.cityId].domain : null;
      if (domain && state.mode === 'redirect') {
        const url = '//' + window.location.href.replace(/^(.*)?(bududoma[^/]+)(.*)?$/, domain + '.$2');
        window.location.assign(url + '?setCity');
        return state;
      }

      // change without redirect
      return state.merge({
        geoId: action.cityId,
        cityName: action.cityId ? state.citiesArray[action.cityId].title : null,
        domain: action.cityId ? state.citiesArray[action.cityId].domain : null,
      });
    default:
      return state;
  }
}


// selectors

export const getGeo = state => {
  return state.geo;
};


export const getCities = state => {
  return state.geo.citiesArray;
};

export const getSelectedCity = state => {
  return state.geo.cityName;
};

export const getSelectedCityId = state => {
  return state.geo.geoId;
};

export const isCitySelected = state => {
  return state.geo.geoId != null;
};

export const getAreas = state => {
  return state.geo.city ? state.geo.city.areas : null;
};
