import bududomaService from 'services/bududoma';
import * as types from 'actions/types';

export const fetchTip = (id) => {
  return async (dispatch) => {
    try {
      dispatch({type: types.TIP_PAGE_LOADING});
      const page = await bududomaService.getTipPage(id);
      dispatch({type: types.TIP_PAGE_FETCHED, page});
    } catch (error) {
      console.error(error);
    }
  };
}

export const fetchTipList = () => {
  return async (dispatch) => {
    try {
      dispatch({type: types.TIP_LIST_LOADING});
      const [totalCount, nextHref, tipList] = await bududomaService.getTipList();
      dispatch({type: types.TIP_LIST_FETCHED, totalCount, nextHref, tipList});
    } catch (error) {
      console.error(error);
    }
  };
};
