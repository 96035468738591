import React from 'react'
import PropTypes from 'prop-types'
import {IoMdHeart, IoMdHeartEmpty} from "react-icons/all"

const FavIcon = ({onClick, active}) => (
  active === true ?
    <div className="favorite favorite-active" onClick={onClick}><IoMdHeart/></div>
    : <div className="favorite" onClick={onClick}><IoMdHeartEmpty/></div>
)

FavIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

export default FavIcon
